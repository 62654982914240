import { DeleteWorkbook, GetMyLibraryWorkbooks } from '../core/store/workbook/workbook.actions';
import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { CurriculumService } from '../core/services/curriculum.service';
import {CourseWorkbooksService} from '../core/services/course-workbooks.service';
import {WorkbooksService} from '../core/services/workbooks.service';
import { switchMap, take, tap, filter, map, takeUntil, reduce, delay, distinctUntilChanged } from 'rxjs/operators';
import { combineLatest, Observable, forkJoin, of, Subject, BehaviorSubject } from 'rxjs';
import {CourseService} from '../core/services/courses.service';
import { ListenerService } from '../core/services/listener.service';
import {LessonPlansService} from '../core/services/lesson-plans.service';
import { BARTON_USER, _variable_images } from '../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

import {
  faChevronUp,
  faChevronDown,
  faArrowCircleUp,
  faArrowCircleDown,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import {UtilityService} from '../core/services/utility.service';
import { CourseWorkbookMenuComponent } from '../core/components/workbooks/course-workbooks.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ActivityTypeService} from '../core/services/activity-type.service';
import { MatDialog } from '@angular/material/dialog';

import { ManageCourseDialogComponent } from '../core/components/manage-course-dialog/manage-course-dialog.component';
import {SubscriptionService} from '../core/services/subscription.service';
import { TutorialDialogComponent } from '../core/components/support/support.component';
import { ConfirmationDialogComponent } from '../core/components/confirmation-dialog/confirmation-dialog.component';

import { SettingsService } from '../core/services/settings.service';

import {LessonPlansStepsService} from '../core/services/lesson-plans-steps.service';
import { DataService } from '../core/services/data.service';
import { NavbarListenerService } from '../core/services/navbar-listener-service..service';
import * as dummyActions from '../core/store/dummy/dummmy-actions';
import { Store, select } from '@ngrx/store';
import * as reducers from '../core/store';
import * as CoursesActions from '../core/store/courses/courses.actions';
import * as SubscriptionsActions from '../core/store/subscriptions/subscriptions.actions';
import * as planActions from '../core/store/plan/plan.actions';
import * as WorkbookActions from '../core/store/workbook/workbook.actions';
import * as CourseWorkbookActions from '../core/store/course-workbook/course-workbook.actions';
import * as SetCourseActions from '../core/store/set-course/set-course.actions';

import { reducer } from '../core/store/dummy/dummy-reducer';
import { ClearAllLessons, CopyLesson, DeleteLesson, GetAllLessonSteps } from '../core/store/lesson/lesson.actions';
import { GetWorkbook } from '../core/store/workbook/workbook.actions';
import { getLessonStep } from '../core/store/edit-lesson/edit-lesson.reducer';
import { GetLessonStep } from '../core/store/edit-lesson/edit-lesson.actions';
import {ActivityHeaderService} from '../core/services/activity-header.service';
import { AsyncPipe, Location } from '@angular/common';
import { MessageDialogComponent } from '../core/components/message-dialog/message-dialog.component';
import { ClearWorkbookActivity } from '../core/store/workbook-activity/workbook-activity.actions';
import { ClearActivityInitData } from '../core/store/activity/activity.actions';
import {ResendEmailVerification} from '../core/store/account/account.actions';
import {AccountService} from '../core/services/account.service';

@Component({
  selector: 'app-curriculum',
  templateUrl: './html/curriculum.html',
  styleUrls: ['../assets/css/main.css',
              '../assets/scss/fontawesome.scss',
              '../assets/scss/brands.scss',
              '../assets/scss/regular.scss',
              '../assets/scss/solid.scss',
              './curriculum.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CurriculumComponent implements OnInit, OnDestroy {
  @Input() courseMenuOpen: boolean;
  workbooks: any[] = [];
  public courseWorkbooks: any[] = [];
  public lessonPlans: any[] = null;

  public courseWorkbooksSubject: any = new BehaviorSubject<any>(this.courseWorkbooks);
  public lessonPlansSubject: any = new BehaviorSubject<any>(this.lessonPlans);

  public lessonPlans$ = this.lessonPlansSubject.asObservable();
  public courseWorkbooks$ = this.courseWorkbooksSubject.asObservable();

  courses: any[] = [];
  currentCourse: any;
  lessonPlanOpen: any[] = [];
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faArrowCircleUp = faArrowCircleUp;
  faArrowCircleDown = faArrowCircleDown;
  faPlusCircle = faPlusCircle;
  activityDialog: any;
  currentWorkbook: any;
  userId: string;
  bartonUser = BARTON_USER;

  public activities: any[] = [];
  public isLoading = true;
  canReOrder = false;
  subscriptionId: string;
  selectedTabIndex = 0;
  hideWorkbookTabsPredicate = true;
  hideWorkbookTabsPredicate$ = new Subject();

  helpIconImage: any;
  selectedPlan: string;
  dummy$: any;
  isChanged = false;
  private unsubscribe$: Subject<void> = new Subject();

  public isLoadingSubject: any = new BehaviorSubject<any>(true);
  isLoadingObs$ = this.isLoadingSubject.asObservable();

  constructor(
    private _curr: CurriculumService,
    private courseWorkbookService: CourseWorkbooksService,
    private workbookService: WorkbooksService,
    private courseService: CourseService,
    private lessonPlansService: LessonPlansService,
    private lessonPlanStepService: LessonPlansStepsService,
    private utilityService: UtilityService,
    private activityTypeService: ActivityTypeService,
    private dialog: MatDialog,
    private listener: ListenerService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private navbarListenerService: NavbarListenerService,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private store: Store,
    private async: AsyncPipe,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private curriculumService: CurriculumService,
    private accountService: AccountService
  ) {
    this.route.params.subscribe(param => {
      if (param.type === 'workbook') {
        this.selectedTabIndex = 0;
      } else if (param.type === 'lesson') {
        this.selectedTabIndex = 1;
      } else {
      }
    });
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit() {
    let profile = JSON.parse(localStorage.profile);


    if (profile.email_verified && !profile.user_metadata.emailVerificationAccepted) {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        panelClass: 'custom-confirm-dialog',
        data: {
          title: 'Email Verified',
          content: 'Your email address has been verified.',
          style: 'text-align: left; width: 87%;'
        }
      });

      this.accountService.setEmailVerificationAccepted(profile.user_id).pipe(take(1))
        .subscribe(() => {
          profile.user_metadata.emailVerificationAccepted = true;
          localStorage.setItem('profile', JSON.stringify(profile));
          console.log('email verification accepted');
        });
    }

    // this.isLoading = true;
    this.store.dispatch(new ClearAllLessons(null));
    this.isLoadingObs$.pipe(takeUntil(this.unsubscribe$)).subscribe((evt) => {
      if (evt && this.isChanged) {
        setTimeout(() => {
          this.isLoadingSubject.next(false);
          this.isChanged = false;
        }, 3000);
      }
    });
    ActivityHeaderService.updateOverDeadzone(true);
    // this.spinner.show();
    this.navbarListenerService.navbarCallback(false);
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.helpIconImage = path + 'help-header.png';
    });
    // this.helpIconImage = _variable_images + 'help-header.png';
    this.listener.listener().pipe(takeUntil(this.unsubscribe$)).subscribe((callback) => {
      const payload = callback.split(':');
      if (payload.length > 1) {
        localStorage.setItem('currentCourseId', payload[1]);
        // this.setCourse(payload[1]);
      }

      if (payload.length === 3) {
        this.subscriptionId = payload[2];
      }
    });


    // combineLatest([
    //   this.store.select(reducers.selectGetCourseWorkbookPending),
    //   this.store.select(reducers.selectGetCourseWorkbookSuccess)
    // ])
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe(([pending, success]: any) => {
    //     if (pending && !success) {
    //       // this.spinner.show();
    //       this.isLoading = true;

    //     } else if (!pending && success) {
    //       // this.spinner.show();
    //       this.isLoading = false;

    //     } else {
    //       this.isLoading = true;
    //     }
    //   });
    this.store.select(reducers.selectGetCourseWorkbookSuccess)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(success => {
        if (success) {
          this.isLoading = false;
          this.isLoadingSubject.next(false);
        }
      });


    this.userId = JSON.parse(localStorage.profile).user_metadata.uid;
    // this.currentCourse = this.courseService.currentCourse;
    // this.store.dispatch(new planActions.GetPlan(JSON.parse(localStorage.profile).user_metadata.cusID));
    // this.store.dispatch(new CoursesActions.GetAllCourse(''))
    // this.store.dispatch(new SubscriptionsActions.GetAllSubscriptions(JSON.parse(localStorage.profile).user_metadata.uid))

    combineLatest([
      this.store.select(reducers.getSetCourseData),
      this.store.select(reducers.selectGetAllCourseWorkbook),
      this.store.select(reducers.selectGetMyLibraryWorkbooks),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([course, courseWorkbooks, workbooks]) => {
        if (!course || !courseWorkbooks) {
          this.currentCourse = null;
          if (!courseWorkbooks) {
            console.log('course workbooks', courseWorkbooks);
            // this.isLoading = true;
          }
          // this.spinner.show();
          return;
        }
        this.currentCourse = course;

        this.store.dispatch(new GetAllLessonSteps(course._id));

        if (course.subscriptionId) {
          this.subscriptionId = course.subscriptionId;
        }

        this.hideWorkbookTabsPredicate = !(this.currentCourse.teacherKey === this.bartonUser && this.currentCourse.teacherKey !== this.userId);
        this.hideWorkbookTabsPredicate$.next(this.hideWorkbookTabsPredicate);
        // this.setLessonPlans(this.currentCourse._id);
        this.workbooks = JSON.parse(JSON.stringify(workbooks));
        this.courseWorkbooksSubject.next(JSON.parse(JSON.stringify(courseWorkbooks)));
        this.sortCourseWorkbooks();
        // this.spinner.hide();
      });

    this.store.select(reducers.selectGetAllLessonSteps)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(lessonPlans => {
        // this.isLoadingSubject.next(!lessonPlans);
        if (!lessonPlans) {
          return;
        }
        this.lessonPlansSubject.next(JSON.parse(JSON.stringify(lessonPlans)));
        this.sortLessonPlans();
        this.lessonPlanOpen = lessonPlans.map(() => ({open: false}));
      });

    this.store.select(reducers.selectActivityTypes)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(activities => {
        if (!activities) {
          this.activities = [];
          return;
        }
        // @ts-ignore
        this.activities = JSON.parse(JSON.stringify(activities)).map(activity => {
          if (activity.name.toLowerCase() === 'blackboard') {
            activity.url = '//activity/blackboard/';
          } else if (activity.name.toLowerCase() === 'workbook tiles') {
            activity.url = '//activity/workbook-tiles/';
          }

          return activity;
        });
        console.log(activities);
      });
    this.listener.getPlan().pipe(takeUntil(this.unsubscribe$)).subscribe((plan: string) => {
      this.selectedPlan = plan;
    });

    combineLatest([
      this.store.select(reducers.isSetCourseLoaded),
      this.store.select(reducers.getAllNonAndSubscriptionCourses)
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([isDefaultCourseLoaded, allCourses]) => {
        if (!isDefaultCourseLoaded && allCourses?.length) {
          // let course = allCourses[0];
          // if (this.utilityService.mobileCheck()) {
          const course = allCourses[0];
          // }
          this.store.dispatch(new SetCourseActions.SetCurriculumCourse({
            course,
            redirect: false
          }));
          setTimeout(() => {
            this.isLoadingSubject.next(false);
          }, 10000);
        }
      });

    combineLatest([
      this.courseWorkbooks$,
      this.lessonPlans$
    ]).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((res: any[]) => {
      const [workbooks, lessons] = res;
      if (workbooks && lessons) {
        this.isLoadingSubject.next(false);
      } else {
        this.isLoadingSubject.next(true);
      }
    });
    // this.isLoading = false;

    this.hideWorkbookTabsPredicate$.pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      const paramsType = this.route.snapshot.params?.type;
      if (!res || paramsType === 'lesson' || this.curriculumService.activeTab === 'lesson') {
        this.selectedTabIndex = 1;
      } else {
        this.selectedTabIndex = 0;
      }
      this.tabClick({index: this.selectedTabIndex});
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openTutorialDialog(): void {
    const dialogRef = this.dialog.open(TutorialDialogComponent, {
      data: {
        title: 'Curriculum',
        link:  'https://videos.sproutvideo.com/embed/069cd9b41510efcf8f/9ab3e41114adb2f4?type=sd'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDialog(currentCourseWorkbook): void {
    localStorage.setItem('currentCourseWorkbook', JSON.stringify(currentCourseWorkbook));
    // TODO: to investigate

    if (!JSON.parse(localStorage.profile).email_verified) {
      this.utilityService.openVerificationEmailDialog();
      return;
    }

    this.currentWorkbook = currentCourseWorkbook;
    if (
      !this.utilityService.isCourseWorkbookActive(currentCourseWorkbook)
    ) {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        panelClass: 'custom-confirm-dialog',
        data: {
          title: 'Workbook Content Missing',
          content: "Please click on the three dots on this workbook and then select 'Edit' to add content to this workbook before beginning it."
        }
      });
      dialogRef.afterClosed().subscribe();
      return;
    }

    this.listener.setCurriculumTab('workbook');
    this.activityDialog = this.dialog.open(CourseWorkbookMenuComponent, {
      panelClass: 'activity-modalbox',
      data: {
        ...currentCourseWorkbook,
        courseWorkbookId: currentCourseWorkbook._id,
        workbookId: currentCourseWorkbook.workbookId,
        activities: this.activities,
        isHomePage: true
      },
      width: '350px'
    });

    this.activityDialog.componentInstance.functions = {
      closeDialog: (activity) => this.activityDialog.close()
    };
  }

  copyWorkbook(courseWorkbookId) {
    const courseWorkbooks: any = this.async.transform(this.courseWorkbooks$);
    const courseWorkbook = courseWorkbooks.find(item => item._id === courseWorkbookId);
    const workbook = this.workbooks.find(item => item._id = courseWorkbook.workbookId);
    this.store.dispatch(new ClearWorkbookActivity());
    this.store.dispatch(new ClearActivityInitData());
    this.store.dispatch(new CourseWorkbookActions.CopyCourseWorkbook({
      courseWorkbook,
      workbook
    }));
  }

  addWorkbook(workbook) {
    const {uid, name} = JSON.parse(localStorage.profile).user_metadata;
    delete workbook._id;
    workbook.owner = uid;
    workbook.ownerName = name;
    this.workbookService
      .create(workbook)
      .pipe(
        take(1),
        switchMap((newWorkbook: any) => {
          return this.addCourseWorkbook(newWorkbook);
        }) ,
        switchMap(() => {
          return combineLatest([
            this.workbookService.getAll(),
            this.courseWorkbookService.getAll(this.currentCourse._id)
          ]);
        })
      )
      .subscribe(([workbooks, courseWorkbooks]) => {
        // @ts-ignore
        this.workbooks = workbooks;
        // @ts-ignore
        this.courseWorkbooksSubject.next(courseWorkbooks);
      });
  }

  addCourseWorkbookClickEvent(workbook) {
    this.addCourseWorkbook(workbook)
      .pipe(
        take(1)
      )
      .subscribe(() => {});
  }

  addCourseWorkbook(workbook) {
    return new Observable(observer => {
      this.courseWorkbookService
        .create({
          ownerKey: workbook.owner,
          workbookId: workbook._id,
          courseId: this.currentCourse._id,
          concept: 'new',
          order: this.workbooks.length
        })
        .pipe(
          switchMap(() => {
            return this.courseWorkbookService.getAll(this.currentCourse._id);
          }),
          take(1)
        )
        .subscribe(courseWorkbooks => {
          // @ts-ignore
          this.courseWorkbooksSubject.next(courseWorkbooks);
          this.sortCourseWorkbooks();
          observer.next(courseWorkbooks);
        });
    });
  }

  editWorkbook(workbookId) {
    this.router.navigate([`workbook-builder/${workbookId}`]);
  }

  deleteCourseWorkbook(id: string) {
    this.lessonPlanStepService
      .getCountByCourseWorkbookId(id)
      .pipe(
        take(1),
        map((count: number) => {
          if (count === 0) {
            // return this.courseWorkbookService.delete(id);
            this.store.dispatch(new CourseWorkbookActions.RemoveWorkbookFromCourse(id));
            return;
          }
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            // panelClass: 'small-dialog',
            data: {
              title: 'Delete Workbook',
              message: 'This course workbook is used in one or more lesson plans, are you sure you wish to delete it?'
            },
          });

          dialogRef.afterClosed().subscribe((canDelete) => {
            if (canDelete) {
              // return this.courseWorkbookService.delete(id);
              this.store.dispatch(new CourseWorkbookActions.RemoveWorkbookFromCourse(id));
            }
            // else {
            //   return new Observable(observer => observer.next(null));
            // }
          });
        }),
        // switchMap(() => this.courseWorkbookService.getAll(this.currentCourse._id))
      )
      .subscribe((courseWorkbooks) => {
          // @ts-ignore
          // this.courseWorkbooks = courseWorkbooks;
        },
        error => {
        }
      );
  }

  confirmDeleteWorkbook(workbookId: string) {
    combineLatest([this.lessonPlanStepService.getCountByWorkbookId(workbookId), this.courseWorkbookService.getCountByWorkbookId(workbookId)])
      .pipe(take(1))
      .subscribe(([lessonPlanStepCount, courseWorkbookCount]) => {
        if (lessonPlanStepCount === 0 && courseWorkbookCount === 0) {
          return this.workbookService.delete(workbookId);
        }

        let confirmDetails = '';
        // @ts-ignore
        if (lessonPlanStepCount > 0) {
          confirmDetails += 'lesson plan steps';
        }

        // @ts-ignore
        if (courseWorkbookCount > 0) {
          confirmDetails += (confirmDetails === '' ? '' : ' and ') + 'course workbooks';
        }

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          // panelClass: 'small-dialog',
          height: '210px',
          width: '350px',
          panelClass: 'custom-confirm-dialog',
          data: {
            title: 'Delete Workbook',
            message: `This workbook is used in one or more ${confirmDetails}. Are you sure you wish to delete it?`
          },
        });

        dialogRef.afterClosed().subscribe((canDelete) => {
          if (canDelete) {
            return this.deleteWorkbook(workbookId);
          }
        });
      });
  }

  deleteWorkbook(workbookId: string) {
    const courseWorkbooks: any = this.async.transform(this.courseWorkbooks$);
    const courseWorkbook = courseWorkbooks.find(item => {
      return workbookId === item.workbookId;
    });
    if (courseWorkbook) {
      this.store.dispatch(new DeleteWorkbook({
        courseWorkbookId: courseWorkbook._id,
        workbookId
      }));
    }

  }
  toggleCourseMenu(courseMenuOpen: boolean) {
    this.courseMenuOpen = courseMenuOpen;
  }
  setCourse(newCourse: string) {
    // return;
    // this.spinner.show();
    combineLatest([this.store.select(reducers.selectGetAllCourses), this.store.select(reducers.selectGetAllSubscription)])
      .pipe(
        take(1),
        switchMap((allCourses: any) => {
          this.courses = allCourses[0].concat(allCourses[1]);
          this.courses = this.courses;
          const currentCourseId = newCourse ? newCourse : this.courses[0]._id;
          this.store.dispatch(new CourseWorkbookActions.GetAllCourseWorkbooks(currentCourseId));
          this.store.dispatch(new CoursesActions.GetCourse(currentCourseId));
          // localStorage.setItem('currentCourseId', currentCourseId);

          this.store.dispatch(new CoursesActions.GetCourse(currentCourseId));
          // localStorage.setItem('currentCourseId', currentCourseId);
          return combineLatest([
            this.workbookService.getAll(),
            this.store.select(reducers.selectGetAllCourseWorkbook),
            this.store.select(reducers.selectGetCourse)
          ]);
        }),
        takeUntil(this.unsubscribe$)
      )
      .pipe(
        delay(50)
      )
      .subscribe(([workbooks, courseWorkbooks, course]) => {


        // @ts-ignore
        this.workbooks = workbooks;
        // @ts-ignore
        this.currentCourse = course;
        this.courseService.currentCourse = this.currentCourse._id;
        this.hideWorkbookTabsPredicate = !(this.currentCourse.teacherKey === this.bartonUser && this.currentCourse.teacherKey !== this.userId);
        this.hideWorkbookTabsPredicate$.next(this.hideWorkbookTabsPredicate);
        // this.setLessonPlans(this.currentCourse._id);

        // @ts-ignore
        this.courseWorkbooksSubject.next(courseWorkbooks);

        this.sortCourseWorkbooks();
        // this.listener.curriculumCallback().subscribe((tab: string) => {
        //   if (tab === 'workbook') {
        //     this.selectedTabIndex = 0;
        //   } else {
        //     this.selectedTabIndex = 1;
        //   }
        // });
        // this.spinner.hide();
        // this.isLoading = false;

      });
  }
  // setLessonPlans(courseId: string) {
    // return this.lessonPlansService.getAllWithSteps(courseId)
    // .subscribe((lessonPlans: any[]) => {
    //   this.lessonPlans = lessonPlans;
    //   this.sortLessonPlans();
    //   this.lessonPlanOpen = lessonPlans.map(() => ({open: false}));

    //   if (Number.isNaN(this.selectedTabIndex)) {
    //    this.selectedTabIndex = 0;
    //   }
    // });
  // }

  toggleOpen(index: any) {
    this.lessonPlanOpen[index].open = !this.lessonPlanOpen[index].open;
  }

  tabClick(tab) {
    let tabType: 'lesson' | 'workbook';
    const params = this.route.params['value']
    this.selectedTabIndex = tab.index;
    const urlArr = (this.activatedRoute.url as any).value[0];
    let url = urlArr.path;

    if (tab.index === 0) {
      tabType = 'workbook';
    } else if (tab.index === 1) {
      tabType = 'lesson';
    }
    urlArr.parameters.type = tabType
    Object.entries(urlArr.parameters).forEach(([key, value]) => {
      if (value) {
        url += `;${key}=${value}`
      }
    })
    this.curriculumService.activeTab = tabType
    this.location.go(url)
  }

  edit(lessonPlanId: string) {
    // $event.stopPropagation();
    let lessonPlans: any = this.async.transform(this.lessonPlans$);
    const order = lessonPlans.length === 0 ? 0 : this.sortBy('order')[lessonPlans.length - 1].order + 1;
    this.router.navigate([`edit-lesson-plan/${lessonPlanId}/${this.currentCourse._id}/${order}`]);
  }

  begin(lessonPlan: any) {    // $event.stopPropagation();
    if (lessonPlan.lessonPlanStepCount === 0 || !lessonPlan.lessonPlanSteps.length) {
      return;
    }

    if (!JSON.parse(localStorage.profile).email_verified) {
      this.utilityService.openVerificationEmailDialog();
      return;
    }

    this.lessonPlansService
      .getFirstStep(lessonPlan._id)
      .pipe(take(1))
      .subscribe((firstStep: any) => {
        const url = this.utilityService.getLessonPlanStepUrl(
          firstStep.activityName,
          firstStep.courseWorkbookId,
          lessonPlan._id,
          firstStep._id
        );
        this.store.dispatch(new GetLessonStep(firstStep._id));
        this.router.navigate([url]);
        this.listener.setCurriculumTab('lesson');
      });
  }

  copy(lessonPlanId: string) {
    // $event.stopPropagation();
    // this.lessonPlansService
    //   .copy(lessonPlanId)
    //   .subscribe(
    //     (copiedLesson: any) => {
    //       copiedLesson.order = this.lessonPlans[this.lessonPlans.length - 1].order + 1;
    //       this.lessonPlans.push(copiedLesson);
    //       this.lessonPlanOpen.push({open: false});
    //     },
    //     error => {
    //     }
    //   );
    this.store.dispatch(new CopyLesson(lessonPlanId));
  }

  delete(lessonPlanId: string) {
    // $event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      // panelClass: 'small-dialog',
      height: '180px',
      width: '360px',
      panelClass: 'custom-confirm-dialog',
      data: {
        title: 'Delete Lesson Plan',
        message: `Are you sure you want to delete this lesson?`
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.lessonPlansService
        // .delete(lessonPlanId)
        // .pipe(
        //   take(1)
        // )
        // .subscribe(
        //   () => {
        //     const matchedIndex = this.lessonPlans.findIndex(lessonPlan => lessonPlan._id === lessonPlanId);
        //     this.lessonPlans.splice(matchedIndex, 1);
        //     this.lessonPlanOpen.splice(matchedIndex, 1);
        //   },
        //   error => {
        //   }
        // );
        this.store.dispatch(new DeleteLesson(lessonPlanId));
      }
    });
  }

  move($event: any, index: number, isUp: boolean) {
    let lessonPlans: any = this.async.transform(this.lessonPlans$);
    $event.stopPropagation();
    if ((isUp && index === 0) || (!isUp && index === lessonPlans.length - 1)) {
      return;
    }

    const nextIndex = isUp ? index - 1 : index + 1;
    const tempOrder = lessonPlans[index].order;
    lessonPlans[index].order = lessonPlans[nextIndex].order;
    lessonPlans[nextIndex].order = tempOrder;
    this.lessonPlansSubject.next(lessonPlans);
  }

  sortBy(prop: string): any[] {
    let lessonPlans: any = this.async.transform(this.lessonPlans$);

    return lessonPlans.sort((a, b) => {
      if (a[prop] < b[prop]) {
        return -1;
      }

      if (a[prop] > b[prop]) {
        return 1;
      }

      return 0;
    });

  }

  createNew() {
    if (!JSON.parse(localStorage.profile).email_verified) {
      this.utilityService.openVerificationEmailDialog();
      return;
    }

    let lessonPlans: any = this.async.transform(this.lessonPlans$);

    const order = lessonPlans.length === 0 ? 0 : this.sortBy('order')[lessonPlans.length - 1].order + 1;
    this.router.navigate([`edit-lesson-plan/new/${this.currentCourse._id}/${order}`]);
  }

  openManageDialog() {
    this.store.select(reducers.selectGetAllCourses)
    .pipe(take(1))
    .subscribe((courses: any) => {
      const previousSelectedCourse = JSON.parse(localStorage.getItem('previousSelectedCourse'));
      const isFirstCourse = () => {
        if (courses.length > 0 && courses[0]._id === previousSelectedCourse?._id) {
          return true;
        }
        return false;
      };

      const mustDelete = () => {
        const usersCourses = courses.filter((value) => {
          return !value.subscriptionId;
        });
        if (usersCourses.length < 2 || isFirstCourse()) {
          return false;
        } else {
          return true;
        }
      };

      const dialogRef = this.dialog.open(ManageCourseDialogComponent, {
        width: '250px',
        panelClass: 'manage-course-modalbox',
        data: { currentCourse: {...this.currentCourse}, canDelete: mustDelete(), isFirstCourse: isFirstCourse() }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'deleted') {
          localStorage.setItem('currentCourseId', courses[0]._id);
          // this.router.navigateByUrl('my-curriculum');
          this.listener.callback(`course:${courses[0]._id}`);
          if (mustDelete && (this.selectedPlan !== 'Superhero' && this.selectedPlan !== 'Hero' && this.selectedPlan !== 'Demo')) {
            this.store.dispatch(new CoursesActions.DeleteCourse(this.currentCourse._id));
          }
        }
      });

    });
  }

  quickStart() {
    // navigate to the first workbooks blackboard activity
    const courseWorkbooks: any = this.async.transform(this.courseWorkbooks$);
    const workbook = courseWorkbooks[0];
    this.router.navigate([`activity/blackboard/${workbook._id}`]);
  }

  reOrder(reOrder: boolean) {
    if (!reOrder) {
      this.isLoadingSubject.next(true);
      if (this.selectedTabIndex === 0) {
        this.courseWorkbooks$.pipe(switchMap((courseWorkbooks: any[]) => {
          const requests = courseWorkbooks.map(cw => this.courseWorkbookService.update({order: cw.order}, cw._id));
          return forkJoin(requests);
        }))
        .pipe(take(1))
        .subscribe(result => {
          this.isLoadingSubject.next(false);
        });
      }
      if (this.selectedTabIndex === 1) {
        this.lessonPlans$.pipe(switchMap((lessonPlans: any[]) => {
          const requests = lessonPlans.map(lp => this.lessonPlansService.update(lp._id, {order: lp.order}));
          return forkJoin(requests);
        }))
        .pipe(take(1))
        .subscribe(result => {
          this.isLoadingSubject.next(false);
        });
      }
    }
    if (!this.isChanged) {
      setTimeout(() => {
        this.isLoadingSubject.next(false);
      }, 100);

    }
    this.canReOrder = reOrder;
  }

  unsubscribe(): void {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '210px',
        width: '350px',
        panelClass: 'custom-confirm-dialog',
        data: {
          title: 'Unsubscribe',
          // message:  'Would you like to unsubscribe from this course?'
          message: 'Unsubscribing from a course is a permanent action. How would you like to proceed?',
          confirmLabel: 'Unsubscribe'
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.store.dispatch(new SubscriptionsActions.DeleteSubscription(this.subscriptionId));
          window.location.reload();
        }
      });
  }

  changeCourseWorkbookOrder(courseWorkbooks: any) {
    this.courseWorkbooksSubject.next(courseWorkbooks);
    // this.isChanged = true;
    // courseWorkbooks = JSON.parse(JSON.stringify(courseWorkbooks));
    // const reorderedCourseWorkbooks = courseWorkbooks.map((cw, index) => {
    //   cw.order = index;
    //   return cw;
    // });

    // const requests = reorderedCourseWorkbooks.map(cw => this.courseWorkbookService.update({order: cw.order}, cw._id));

    // combineLatest(requests)
    //   .pipe(take(1))
    //   .subscribe((result: any[]) => {
    //     this.setCourse(this.currentCourse._id);
    //   });
  }

  changeLessonPlanOrder(lessonPlans: any) {
    this.lessonPlansSubject.next(lessonPlans);

    // const reorderedLessonPlans = lessonPlans.map((lp, index) => {
    //   lp.order = index;
    //   return lp;
    // });

    // const requests = reorderedLessonPlans.map(lp => this.lessonPlansService.update(lp._id, {order: lp.order}));

    // combineLatest(requests)
    //   .pipe(take(1))
    //   .subscribe((result: any[]) => {
    //     this.setLessonPlans(this.courseService.currentCourse._id);
    //   });
  }

  private sortByOrder(a: any, b: any) {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }

    return 0;
  }

  private sortCourseWorkbooks() {
    let courseWorkbooks: any = this.async.transform(this.courseWorkbooks$);
    courseWorkbooks = [...courseWorkbooks].sort(this.sortByOrder);
    this.courseWorkbooksSubject.next(courseWorkbooks);
    if (this.isChanged) {
      setTimeout(() => {
        this.isLoadingSubject.next(false);
        this.isChanged = false;
      }, 100);
  }

  }

  private sortLessonPlans() {
    let lessonPlans: any = this.async.transform(this.lessonPlans$);
    lessonPlans = lessonPlans.sort(this.sortByOrder);
    this.lessonPlansSubject.next(lessonPlans);
  }

  toggleMouseEnter(b: boolean) {
    ActivityHeaderService.updateOverDeadzone(b);
  }
}
