
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of} from 'rxjs';
import { first, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { TilesUpdatingOptionEnum, WordsUpdatingOptionEnum } from '../components/course-workbook-edit/course-workbook-edit.component';
import { getCurrentSetting, selectActivityOrLessonFixStatus, selectBlackboardInit, selectGetAllCourseWorkbook, selectGetLessonStep, selectGetWorkbookActivity, selectMiniTilesInit, selectWorkbookTilesInit } from '../store';
import { BlackboardInit, MiniTilesInit, UpdateInitDataCourseWorkbookActivitySuccess, WorkbookTilesInit } from '../store/activity/activity.actions';
import { UpdateLessonStepSuccess } from '../store/edit-lesson/edit-lesson.actions';
import { SetActivityOrLessonFixStatus } from '../store/user-interface/user-interface.actions';
import { UpdateWorkbookActivity } from '../store/workbook-activity/workbook-activity.actions';
import { LessonPlansStepsService } from './lesson-plans-steps.service';
import { UtilityService } from './utility.service';
import { WordsService } from './words.service';

@Injectable()

export class WorkbookActivityLessonStepService {

  private _setting: any;
  private _courseWorkbooks: any;
  private _userId = JSON.parse(localStorage.profile).user_metadata.uid;
  private _updateStatus: 'updating' | 'done';
  constructor(
    private store: Store,
    private wordsService: WordsService,
    private utiltiyService: UtilityService,
    private lessonPlanStepsService: LessonPlansStepsService
  ) {
    this.store.select(getCurrentSetting)
      .subscribe(setting => this._setting = setting);
    this.store.select(selectGetAllCourseWorkbook)
      .subscribe(courseWorkbooks => this._courseWorkbooks = courseWorkbooks);

  }

  getDefaultUpdatingValues() {
    return {
      wordUpdating: this._setting.wordUpdating || WordsUpdatingOptionEnum.Manual,
      tileUpdating: this._setting.tileUpdating || TilesUpdatingOptionEnum.Manual
    };
  }

  // getAllTilesFromSetting() {
  //   let result = [];
  //   for (const [row, rowValues] of Object.entries(this._setting.bank)) {
  //     for (const [col, colValue] of Object.entries(rowValues)) {
  //       result.push(colValue)

  //     }
  //   }
  //   return [...new Set(result)];
  // }

  // Words Updating Options
  applyRemoveWordsOption(data, courseWorkbook) {
    const newWords = data.words.slice(0, data.numWords).filter(item1 => {
      return courseWorkbook.workbook.preview.find(item2 => item1.wordid === item2.wordid);
    });

    if (newWords.length !== data.words.length) {
      return newWords;
    }
    return data.words;
  }
  applyReplaceWordsOption(data, courseWorkbook) {
    let originalWords = data.words.slice(0, data.numWords);
    let filteredWords = originalWords.filter(item1 => {
      return courseWorkbook.workbook.preview.find(item2 => item1.wordid === item2.wordid);
    });
    if (originalWords.length !== filteredWords.length) {
      let newWords = [...filteredWords];
      courseWorkbook.workbook.preview.some(item1 => {
        const isExist = [...originalWords, ...filteredWords].find(item2 => item1.wordid === item2.wordid);
        if (!isExist) {
          newWords.push(item1);
          if (newWords.length === originalWords.length) {

            return true;
          }
        }
      });
      return newWords;
    }
    return data.words;
  }
  applyUseWordsOption(courseWorkbook) {
    return courseWorkbook.workbook.preview;
  }

  // Tile Updating Options
  applyActiveWordsTilesOption(data): Observable<any> {
    let backupTiles: any[];
    const activityTypeId = data?.activityTypeId || data?.activityId;
    // if (activityTypeId === 'workbookTiles') {
    //   backupTiles = data.tiles?.length ? data.tiles : [];
    // } else if (activityTypeId === 'miniTiles') {
    //   backupTiles = data.miniTiles?.length ? data.miniTiles : [];
    // }
    backupTiles = data.tiles?.length ? data.tiles : [];

    const ids = data.words.map(word => word.wordid);
    return this.wordsService
    .getTilesByWordIds(this._userId, ids)
    .pipe(
      take(1),
      switchMap((tiles: any) => {
        // const newTiles = [...new Set([...tiles.flat()])].filter(tile => tile);
        // // this.tiles = newTiles;
        // return newTiles;
        const generatedTiles =  [...new Set([...tiles.flat()])];
        const generatedTilesStr = JSON.stringify(generatedTiles.filter(tile => tile).sort((a, b) => a.localeCompare(b)));
        const oldTilesStr = JSON.stringify(backupTiles.filter(tile => tile).sort((a, b) => a.localeCompare(b)));
        if (generatedTilesStr !== oldTilesStr) {
          let updatedTiles = backupTiles.filter(tile1 => {
            return generatedTiles.find(tile2 => tile1 === tile2);
          });
          const newAddTiles = generatedTiles.filter(n => !updatedTiles.includes(n));
          updatedTiles = [...updatedTiles, ...newAddTiles];
          if (data.isTilesSorted) {
            return this.utiltiyService.sortDroppedTiles(updatedTiles);
          } else {
            return of(updatedTiles);
          }
        } else {
          return of(backupTiles);
        }
      })
    );
  }

  applyExactTilesFilterTilesOption(tiles = [], courseWorkbook) {
    const exactTiles = courseWorkbook.workbook.filters.exactTiles?.map(item => item?.tile ? item.tile : item);
    return this.compareTiles(tiles, exactTiles);

  }

  applyTilesFromTileLayout(tiles = []) {
    const settingTiles = Object.keys(this._setting.bank).map(rowKeys => this._setting.bank[rowKeys]).map(column => Object.values(column)).flat().sort();
    return this.compareTiles(tiles, settingTiles);
  }


  // Fix incomplete words data or properties
  fixWordsData(data, courseWorkbook) {
    let words = [...data.words];
    // words.forEach((word, index) => {
    //   const found = courseWorkbook.fullWords.find(data => data.wordid === word.wordid)
    //   if (found) {
    //     words[index] = found;
    //   }
    // })
    let wordIds = words.map(word => word.wordid);
    return this.wordsService.getWordsByWordIds(wordIds);
  }


  updateWorkbookActivity(workbookActivity, activityType) {
    const userId = JSON.parse(localStorage.profile).user_metadata.uid;
    const customerId = JSON.parse(localStorage.profile).user_metadata.cusID;

    this._updateStatus = 'updating';
    // TODO: checking for lesson step if it words and tiles are Array
    // Create separate updateLessonPlanStep method
    if (!Array.isArray(workbookActivity.words)) {
      workbookActivity.words = [];
    }
    if (!Array.isArray(workbookActivity.tiles)) {
      workbookActivity.tiles = [];
    }

    if (
      workbookActivity.activityTypeId === 'workbookTiles' ||
      workbookActivity.activityTypeId === 'miniTiles'
      // workbookActivity.activityTypeId === 'blackboard'
    ) {
      this.store.dispatch(new UpdateInitDataCourseWorkbookActivitySuccess(workbookActivity));
      activityType = workbookActivity.activityTypeId.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase());
    }

    this.store.dispatch(new SetActivityOrLessonFixStatus('updated'));
    this.store.dispatch(new UpdateWorkbookActivity({
      courseWorkbookActivity: workbookActivity,
      initData: {
        activityType,
        courseWorkbookId: workbookActivity.courseWorkbookId,
        lessonPlanStepId: null,
        userId,
        customerId
      }
    }));
  }

  updateLessonPlanStep(data) {
    this._updateStatus = 'updating';
    const wordIds = data.words.map(word => word.wordid);
    const userId = JSON.parse(localStorage.profile).user_metadata.uid;
    const customerId = JSON.parse(localStorage.profile).user_metadata.cusID;

    combineLatest([
      this.lessonPlanStepsService.updateMany([data]),
      this.wordsService.getTilesByWordIds(userId, wordIds)
    ])
    .pipe(
      take(1)
    )
    .subscribe((res: any) => {
      const activityTypeId = data.activityId || data.activityTypeId;
      const courseWorkbookId = data.courseWorkbookId;
      const lessonPlanStepId = data._id;
      const actionObj = {
        courseWorkbookId,
        lessonPlanStepId,
        userId,
        customerId
      };
      this.store.dispatch(new UpdateLessonStepSuccess({
        id: data._id,
        lessonPlanStep: data}
      ));

      if (activityTypeId === 'blackboard') {
        this.store.dispatch(new BlackboardInit(actionObj));
      } else if (activityTypeId === 'miniTiles') {
        this.store.dispatch(new MiniTilesInit(actionObj));
      } else if (activityTypeId === 'workbookTiles') {
        this.store.dispatch(new WorkbookTilesInit(actionObj));
      }
      this.store.dispatch(new SetActivityOrLessonFixStatus('updated'));
    });
  }


  fixActivityOrStepData(
    activityType: 'workbookTiles' | 'miniTiles' | 'spelling',
    dataType?: 'lessonPlanStep' | 'courseWorkbookActivity',
  ) {
    let initDataObs: Observable<any>;
    let previousInitDataValue: any;
    this._updateStatus = null;

    // const activityTypeId = (data?.activityTypeId ? data.activityTypeId : data?.activityId).split(/(?=[A-Z])/).join('-')
    if (activityType === 'workbookTiles') {
      initDataObs = this.store.select(selectWorkbookTilesInit);
    } else if (activityType === 'miniTiles') {
      initDataObs = this.store.select(selectMiniTilesInit);
    } else if (activityType === 'spelling') {
      // initDataObs = this.store.select(selectMiniTilesInit);
      initDataObs = (dataType === 'lessonPlanStep' ?  this.store.select(selectGetLessonStep) : this.store.select(selectGetWorkbookActivity))
        .pipe(map(
          (data: any) => {
            const courseWorkbook = this._courseWorkbooks.find(courseWorkbook => courseWorkbook._id === data.courseWorkbookId);
            let obj = {
              courseWorkbook: courseWorkbook,
            };
            obj[dataType] = data;
            return obj;
          })
        );

    }
    return combineLatest([
      // this.store.select(selectGetWorkbookActivity),
      // this.store.select(selectMiniTilesInit),
      initDataObs,
      this.store.select(getCurrentSetting),
      this.store.select(selectActivityOrLessonFixStatus)
    ]).pipe(
      tap(([initData, setting, updateStatus]: any[]) => {
        if (!(initData && setting && updateStatus)) {
          return;
        }
        let data = JSON.parse(JSON.stringify(initData.lessonPlanStep || initData.courseWorkbookActivity));
        if (activityType === 'miniTiles') {
          data.tiles = data.miniTiles;
        }
        // if (!(!this._updateStatus &&
        //   ( data?.wordUpdating && data?.tileUpdating && data.hasOwnProperty('ignoreWhizzimap'))
        // )) {
        if (!this._updateStatus) {
          // let updatedWorkbookActivity = JSON.parse(JSON.stringify(initData.courseWorkbookActivity));
          let backupData = JSON.parse(JSON.stringify(initData.lessonPlanStep || initData.courseWorkbookActivity));
          let courseWorkbook = JSON.parse(JSON.stringify(initData.courseWorkbook));
          if (!(data.wordUpdating && data.tileUpdating)) {
            const { wordUpdating, tileUpdating } = this.getDefaultUpdatingValues();
            data.wordUpdating = wordUpdating;
            data.tileUpdating = tileUpdating;
            if ((data.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased && !courseWorkbook.workbook.filters.exactTiles?.length) ||
            !data.words?.length) {
              // tslint:disable-next-line:no-unused-expression
              data.tileUpdating === TilesUpdatingOptionEnum.Manual;
            }
            if (data.tileUpdating === TilesUpdatingOptionEnum.Manual) {
              // if (activityType === 'miniTiles' && data.miniTiles?.length) {
              //   data.tiles = data.miniTiles;
              //   data.miniTiles = null;
              // } else 
              if (!data.tiles?.length) {
                data.tiles = this.applyTilesFromTileLayout(data.tiles);
              }
            }
            data.numWords = this._setting[activityType]?.wordNum || 15;
          }
          // if (data.wordUpdating === WordsUpdatingOptionEnum.RemoveWords) {
          //   data.words = this.applyRemoveWordsOption(data, courseWorkbook)
          // } else if (data.wordUpdating === WordsUpdatingOptionEnum.ReplaceWords) {
          //   data.words = this.applyReplaceWordsOption(data, courseWorkbook)
          // } else if (data.wordUpdating === WordsUpdatingOptionEnum.UseWords) {
          //   data.words = this.applyUseWordsOption(courseWorkbook);
          // }
          // data.numWords = data.words.length;
          data.ignoreWhizzimap = data.ignoreWhizzimap ?? true;
          // if (data.words.find(word => !word?.displayWord)) {
          //   data.words = this.fixWordsData(data, courseWorkbook);
          // }
          // if (data.tileUpdating === TilesUpdatingOptionEnum.TilesFromTileLayout) {
          //   data.tiles = this.applyTilesFromTileLayout(data.tiles)
          // } else if (data.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased) {
          //   if (courseWorkbook.workbook.filters.exactTiles?.length) {
          //     // data.tiles = courseWorkbook.workbook.filters.exactTiles.map(item => item?.tile ? item.tile : item);
          //     data.tiles = this.applyExactTilesFilterTilesOption(data.tiles, courseWorkbook)
          //   } else {
          //     data.tileUpdating = TilesUpdatingOptionEnum.Manual;
          //   }
          // } else if (data.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased) {
          //   let observables = [this.applyActiveWordsTilesOption(data)]
          //   if (data.words.find(word => !word?.displayWord)) {
          //     observables.push(this.fixWordsData(data, courseWorkbook))
          //   } else {
          //     observables.push(of([]))
          //   }

          //   combineLatest(observables).pipe(take(1)).subscribe(([tiles, updatedWords]) => {
          //     data.tiles = tiles;
          //     if (activityType === 'miniTiles') {
          //       data.miniTiles = null;
          //     }
          //     if (updatedWords) {
          //       data.words = updatedWords;
          //     }

          //     if (JSON.stringify(previousInitDataValue) !== JSON.stringify(data)) {
          //       previousInitDataValue = data;
          //       if (initData.lessonPlanStep) {
          //         this.lessonPlanStepsService.updateMany([data])
          //         .subscribe(res => {
          //           this.store.dispatch(new UpdateLessonStepSuccess({
          //             id: data._id,
          //             lessonPlanStep: data}
          //           ));
          //           this.store.dispatch(new SetActivityOrLessonFixStatus('updated'))
          //         })
          //       } else {
          //         this.updateWorkbookActivity(data, activityType);
          //       }
          //     }
          //   })
          //   return;

          // }

          // let observable;
          // if (data.words.find(word => !word?.displayWord)) {
          //   observable = this.fixWordsData(data, courseWorkbook)
          // } else {
          //   observable = of(null)
          // }
          // observable.pipe(take(1)).subscribe(updatedWords => {
          //   if (updatedWords) {
          //     data.words = updatedWords.slice(0, data.numWords);
          //   }
          //   if (activityType === 'miniTiles' && data.miniTiles?.length) {
          //     data.tiles = data.miniTiles;
          //     data.miniTiles = null
          //   }

          //   if (JSON.stringify(previousInitDataValue) !== JSON.stringify(data)) {
          //     previousInitDataValue = data;
          //     if (initData.lessonPlanStep) {
          //       this.lessonPlanStepsService.updateMany([data])
          //         .subscribe(res => {
          //           this.store.dispatch(new UpdateLessonStepSuccess({
          //             id: data._id,
          //             lessonPlanStep: data}
          //           ));
          //           this.store.dispatch(new SetActivityOrLessonFixStatus('updated'))
          //         })
          //     } else {
          //       this.updateWorkbookActivity(data, activityType);
          //     }
          //   }
          // })
          if (data.wordUpdating === WordsUpdatingOptionEnum.UseWords) {
            data.numWords = 50;
          }

          this.fixActivityOrStepWords(data, courseWorkbook)
            .pipe(
              switchMap(words => {
                data.words = words;
                data.numWords = data.words.length;
                return this.fixActivityOrStepTiles(data, courseWorkbook);
              }),
              first()
            )
            .subscribe(tiles => {
              data.tiles = tiles;
              if (activityType === 'miniTiles') {
                data.miniTiles = data.tiles;
              }
              if (JSON.stringify(data) !== JSON.stringify(backupData)) {
                if (initData.lessonPlanStep) {
                  this.updateLessonPlanStep(data);
                  return;
                }
                this.updateWorkbookActivity(data, activityType);
              } else {
                this._updateStatus = 'done';
                this.store.dispatch(new SetActivityOrLessonFixStatus('updated'));
              }
            });

          // this.fixActivityOrStepTiles(data, courseWorkbook)
          //   .pipe(take(1))
          //   .subscribe(tiles => {
          //   data.tiles = tiles;
          //   this.updateWorkbookActivity(data, activityType);

          // })
        }

      }),
      takeWhile(
        ([initData, setting, updateStatus]) => {
          let data = JSON.parse(JSON.stringify(initData.lessonPlanStep || initData.courseWorkbookActivity));
          return !(initData && setting && updateStatus === 'updated' && (data?.wordUpdating && data?.tileUpdating && data.hasOwnProperty('ignoreWhizzimap')));
      }, true),
      map(([initData, setting, updateStatus]: any) => {
        const data = initData?.lessonPlanStep || initData?.courseWorkbookActivity;
        return !!(initData && setting && updateStatus === 'updated' && (data?.wordUpdating && data?.tileUpdating && data.hasOwnProperty('ignoreWhizzimap')));
      }),
    );
  }

  // activity that has only words tab
  fixActivityOrStepData2(
    activityType: 'blackboard' | 'wordcards' | 'timedReading' | 'flashcards',
    dataType: 'lessonPlanStep' | 'courseWorkbookActivity',
  ) {
    let obs$;
    this._updateStatus = null;

    if (activityType === 'blackboard') {
      obs$ = this.store.select(selectBlackboardInit);
    } else if (activityType === 'wordcards' || activityType === 'timedReading' || activityType === 'flashcards') {
      obs$ = (dataType === 'lessonPlanStep' ?  this.store.select(selectGetLessonStep) : this.store.select(selectGetWorkbookActivity))
        .pipe(map(
          (data: any) => {
            const courseWorkbook = this._courseWorkbooks.find(courseWorkbook => courseWorkbook._id === data?.courseWorkbookId);
            let obj = {
              courseWorkbook: courseWorkbook,
            };
            obj[dataType] = data;
            return obj;
          })
        );

    }
    return combineLatest([
      obs$,
      this.store.select(selectActivityOrLessonFixStatus)
    ])
    .pipe(
      tap(([initData, updateStatus]: any) => {
        if (!(initData && updateStatus)) {
          return;
        }
        // if (updateStatus !== 'updated') {
        if (!this._updateStatus) {
          let backupData = JSON.parse(JSON.stringify(initData.lessonPlanStep || initData.courseWorkbookActivity));
          let data = JSON.parse(JSON.stringify(initData.lessonPlanStep || initData.courseWorkbookActivity));
          // data.words
          const courseWorkbook = JSON.parse(JSON.stringify(initData.courseWorkbook));
          const type = initData.lessonPlanStep  ? 'lessonPlanStep' : 'activity';

          if (!data.wordUpdating) {
            const { wordUpdating } = this.getDefaultUpdatingValues();
            data.wordUpdating = wordUpdating;
            data.numWords = this._setting[activityType]?.wordNum || 15;
          }

          if (data.wordUpdating === WordsUpdatingOptionEnum.UseWords) {
            data.numWords = 50;
          }

          this.fixActivityOrStepWords(data, courseWorkbook)
            .pipe(
              take(1)
            )
            .subscribe(words => {
              data.words = words;
              data.numWords = data.words.length;
              // this.store.dispatch(new SetActivityOrLessonFixStatus('updated'))

              if (JSON.stringify(data) !== JSON.stringify(backupData)) {
                if (type === 'lessonPlanStep') {
                  this.updateLessonPlanStep(data);
                } else if (type === 'activity') {
                  this.updateWorkbookActivity(data, activityType);
                }
              } else {
                this._updateStatus = 'done';
                this.store.dispatch(new SetActivityOrLessonFixStatus('updated'));
              }

            });

        }
      }),

      takeWhile(([initData, updateStatus]: any) => {
        const data = initData?.lessonPlanStep || initData?.courseWorkbookActivity;
        return !(initData && updateStatus === 'updated' && data?.wordUpdating);
      }, true),
      map(([initData, updateStatus]: any) => {
        const data = initData?.lessonPlanStep || initData?.courseWorkbookActivity;
        return !!(updateStatus === 'updated' && data?.wordUpdating);
      }),
    );
  }

  // activity that has only tiles tab
  fixActivityOrStepData3(
    activityType: 'lettercards',
    dataType: 'lessonPlanStep' | 'courseWorkbookActivity',
  ) {
    let obs$: Observable<any>;
    this._updateStatus = null;

    obs$ = (dataType === 'lessonPlanStep' ?  this.store.select(selectGetLessonStep) : this.store.select(selectGetWorkbookActivity))
    .pipe(map(
      (data: any) => {
        const courseWorkbook = this._courseWorkbooks.find(courseWorkbook => courseWorkbook._id === data?.courseWorkbookId);
        let obj = {
          courseWorkbook: courseWorkbook,
        };
        obj[dataType] = data;
        return obj;
      })
    );
    return combineLatest([
      obs$,
      this.store.select(selectActivityOrLessonFixStatus)
    ])
    .pipe(
      tap(([initData, updateStatus]: any) => {
        if (!(initData && updateStatus)) {
          return;
        }
        if (!this._updateStatus && (initData?.lessonPlanStep || initData?.courseWorkbookActivity)) {
          let backupData = JSON.parse(JSON.stringify(initData.lessonPlanStep || initData.courseWorkbookActivity));
          let data = JSON.parse(JSON.stringify(initData.lessonPlanStep || initData.courseWorkbookActivity));
          const courseWorkbook = JSON.parse(JSON.stringify(initData.courseWorkbook));
          if (!(data.tileUpdating)) {
            const { tileUpdating } = this.getDefaultUpdatingValues();
            data.tileUpdating = tileUpdating;

            if (data.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased) {
              data.tileUpdating = TilesUpdatingOptionEnum.Manual;
            }

            if (data.tileUpdating === TilesUpdatingOptionEnum.Manual) {
              data.tiles = this.applyTilesFromTileLayout(data.tiles);
            }
          }
          data.ignoreWhizzimap = data.ignoreWhizzimap ?? true;

          // if (data.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased) {
          //   // data.tiles = this.applyExactTilesFilterTilesOption(courseWorkbook)
          //   if (courseWorkbook.workbook.filters.exactTiles?.length) {
          //     // data.tiles = courseWorkbook.workbook.filters.exactTiles.map(item => item?.tile ? item.tile : item);
          //     data.tiles = this.applyExactTilesFilterTilesOption(data.tiles, courseWorkbook)
          //   } else {
          //     data.tileUpdating = TilesUpdatingOptionEnum.Manual;
          //   }

          // } else if (data.tileUpdating === TilesUpdatingOptionEnum.TilesFromTileLayout) {
          //   data.tiles = this.applyTilesFromTileLayout(data.tiles)
          // }

          // if (!Array.isArray(data.tiles)) {
          //   data.tiles = []
          // }

          // if (JSON.parse(JSON.stringify(data)) !== JSON.parse(JSON.stringify(backupData))) {
          //   if (dataType === 'lessonPlanStep') {
          //     this.updateLessonPlanStep(data)
          //   } else if (dataType === 'courseWorkbookActivity') {
          //     this.updateWorkbookActivity(data, activityType)
          //   }
          // } else {
          //   this.store.dispatch(new SetActivityOrLessonFixStatus('updated'))
          // }

          this.fixActivityOrStepTiles(data, courseWorkbook)
            .pipe(take(1))
            .subscribe(tiles => {
              data.tiles = tiles;
              this.store.dispatch(new SetActivityOrLessonFixStatus('updated'));

              if (JSON.stringify(data) !== JSON.stringify(backupData)) {
                if (dataType === 'lessonPlanStep') {
                  this.updateLessonPlanStep(data);
                  return;
                }
                this.updateWorkbookActivity(data, activityType);
              } else {
                this._updateStatus = 'done';
                this.store.dispatch(new SetActivityOrLessonFixStatus('updated'));
              }
            });
        }
      }),
      takeWhile(([initData, updateStatus]: any) => {
        const data = initData?.lessonPlanStep || initData?.courseWorkbookActivity;
        return !(initData && updateStatus === 'updated' && data?.tileUpdating && data.hasOwnProperty('ignoreWhizzimap'));
      }, true),
      map(([initData, updateStatus]: any) => {
        const data = initData?.lessonPlanStep || initData?.courseWorkbookActivity;
        return !!(updateStatus === 'updated' && data?.tileUpdating && data.hasOwnProperty('ignoreWhizzimap'));
      }),
    );
  }

  fixActivityOrStepWords(data, courseWorkbook) {
    let words = [];
    
    if (data.wordUpdating === WordsUpdatingOptionEnum.RemoveWords) {
      words = this.applyRemoveWordsOption(data, courseWorkbook);
    } else if (data.wordUpdating === WordsUpdatingOptionEnum.ReplaceWords) {
      words = this.applyReplaceWordsOption(data, courseWorkbook);
    } else if (data.wordUpdating === WordsUpdatingOptionEnum.UseWords) {
      words = this.applyUseWordsOption(courseWorkbook);
    } else {
      words = data.words || [];
    }
    let obs$: Observable<any>;
    if (words.find(word => !word?.displayWord)) {
      let wordIds = words.map(word => word.wordid / 1);
      obs$ = this.wordsService.getWordsByWordIds(wordIds);
    } else {
      obs$ = of(words);
    }

    return obs$.pipe(map(words => {
      return Array.isArray(words) ? words.slice(0, data.numWords) : [];
    }));
  }

  fixActivityOrStepTiles(data, courseWorkbook): Observable<any[]> {
    let obs$;
    const activityId = data.activityId || data.activityTypeId;
    if (data.miniTiles?.length && activityId === 'miniTiles') {
      data.tiles = data.miniTiles;
    }
    if (data.tileUpdating === TilesUpdatingOptionEnum.ExactTilesFilterBased) {
      // const tiles = courseWorkbook.workbook.filters.exactTiles.map(item => item?.tile ? item.tile : item);
      let tiles = data.tiles;
      if (courseWorkbook.workbook.filters.exactTiles?.length) {
        tiles = this.applyExactTilesFilterTilesOption(tiles, courseWorkbook);
      } else {
        data.tileUpdating = TilesUpdatingOptionEnum.Manual;
      }
      // return of(Array.isArray(tiles) ? tiles : []);
      obs$ = of(tiles);
    } else if (data.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased) {
      obs$ = this.applyActiveWordsTilesOption(data);
    } else if (data.tileUpdating === TilesUpdatingOptionEnum.TilesFromTileLayout) {
      const tiles = this.applyTilesFromTileLayout(data.tiles);
      obs$ = of(tiles);
    } else {
      obs$ = of(data.tiles);
    }
    return obs$.pipe(map(tiles => {
      return Array.isArray(tiles) ? tiles : [];
    }));
  }

  checkMissingTiles(activityTiles, wordTilesParam, convertedTiles, forMiniTiles?) {
    const userTiles = Object.keys(this._setting.bank).map(rowKeys => this._setting.bank[rowKeys]).map(column => Object.values(column)).flat();
    if (!Array.isArray(activityTiles)) {
      activityTiles = [];
    }

    let wordTiles = wordTilesParam.map((tiles) => {
      return tiles.map((tile) => {
        let parts = tile.split('.');
        parts[2] = parts[2].toLowerCase();
        parts = parts.join('.');
        return parts;
      });
    });

    const missingBlackboardTiles = [
      ...new Set(
          wordTiles
              .flat()
              .filter(tile => {
                return userTiles.flat().indexOf(tile) === -1
                  && tile.indexOf("clear") === -1 && !convertedTiles.find(item => tile.includes(item));
              }).flat()
      )
    ].filter((tile: any, index, array) => {
      let parts = tile.split('.');
      let excluded = ['1', '2', '3', '4', '-', '_', "'", '.'];
      let exclude = excluded.includes(parts[2]);
      if (exclude) {
        return !exclude;
      } else if (parts[1] === 'blank') {
        return false;
      } else {
        return true;
      }
    });

    const missingActivityTiles = [
      ...new Set(
        wordTiles
          .flat()
          .filter(tile => {
            if (forMiniTiles) {
              return activityTiles.flat().indexOf(tile) === -1 && !convertedTiles.find(item => tile.includes(item));
            }
            return activityTiles.flat().indexOf(tile) === -1 && userTiles.flat().indexOf(tile) !== -1 && !convertedTiles.find(item => tile.includes(item));
          })
          .flat()
      )
    ].filter((tile: any, index, array) => {
      let parts = tile.split('.');
      let excluded = ['1', '2', '3', '4', '-', '_', "'", '.'];
      let exclude = excluded.includes(parts[2]);
      if (exclude) {
        return !exclude;
      } else if (parts[1] === 'blank') {
        return false;
      } else {
        return true;
      }
    });
    return {
      missingActivityTiles,
      missingBlackboardTiles
    };
  }

  private compareTiles(oldTiles, newTiles) {
    const tiles1 = oldTiles.filter(value => newTiles.includes(value));
    const tiles2 = newTiles.filter(value => !oldTiles.includes(value));

    return [...new Set([...tiles1, ...tiles2])];

  }
}

