import { Component, Inject, EventEmitter, Input, OnDestroy, OnInit, Output, AfterViewInit, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { faTimes, faArrowCircleUp, faArrowCircleDown, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import {FilterTypes} from '../../../../../models/filter-types';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Observable, Subscription} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-word-group-cards',
  templateUrl: './html/word-group-cards.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './sentences-and-more.scss'
  ]
})

export class WordGroupCardsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() wordGroupItems: any[] = [];
  @Input() otherItems: any[] = [];
  @Input() searchTerm: string;
  @Input() isUpArrow: boolean;
  @Input() workbookId: any;
  @Input() type: string;
  @Input() isBank = false;
      // @ts-ignore

  @Input() set addingNew( status: any) {
    if (status) {
      this.addNewContent(this.type);
    }
  }
  @Input() model: string;
  @Input() mismatch = '';
  @Input() itemsObservable: Observable<any>;
  @Output() add: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() remove: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() update: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() reorder: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() addContentEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeNewCardEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearMismatchEvent: EventEmitter<any> = new EventEmitter<any>();

  faTimes = faTimes;
  faArrowCircleUp = faArrowCircleUp;
  faArrowCircleDown = faArrowCircleDown;
  faEdit = faEdit;
  faSave = faSave;
  subscriptions = new Subscription();

  wordGroupType = {
    phrase: 'Phrase',
    sentence: 'Sentence',
    passage: 'Passage'
  };
  private abbreviations = [
    '\\.[Bb][Ii][Zz]',
    '\\.[Cc][Oo][Mm]',
    '\\.[Ee][Dd][Uu]',
    '\\.[Ii][Nn][Ff][Oo]',
    '\\.[Mm][Ee]',
    '\\.[Oo][Rr][Gg]',
    '\\s*[Aa]\\.[Dd]\\.',
    '\\s*[Aa]\\.[Mm]\\.',
    '\\s*[Pp]\\.[Mm]\\.',
    '\\s*[Aa][Bb][Bb][Rr]\\.',
    '\\s*[Aa][Uu][Gg]\\.',
    '\\s*[Aa][Vv][Ee]\\.',
    '\\s*[Bb]\\.[Aa]\\.',
    '\\s*[Bb]\\.[Cc]\\.',
    '\\s*[Bb]\\.[Ss]\\.',
    '\\s*[Dd]\\.[Cc]\\.',
    '\\s*[Dd][Ee][Cc]\\.',
    '\\s*[Dd][Rr]\\.',
    '\\s*[Ff][Ee][Bb]\\.',
    '\\s*[Ff][Ll]\\.',
    '\\s*[Ii]\\.[Ee]\\.',
    '\\s*[Aa][Pp][Rr]\\.',
    '\\s*[Mm][Aa][Rr]\\.',
    '\\s*[Jj][Aa][Nn]\\.',
    '\\s*[Jj][Uu][Ll]\\.',
    '\\s*[Jj][Uu][Nn]\\.',
    '\\s*[Jj]\\.[Rr]\\.',
    '\\s*[Mm][Rr]\\.',
    '\\s*[Mm][Rr][Ss]\\.',
    '\\s*[Mm][Ss]\\.',
    '\\s*[Nn][Oo][Vv]\\.',
    '\\s*[Oo][Cc][Tt]\\.',
    '\\s*[Ss][Ee][Pp][Tt]\\.',
    '\\s*:[Ss][Gg][Tt]\\.',
    '\\s*[Ss][Rr]\\.',
    '\\s*[Ss][Tt]\\.',
    '\\s*[Uu]\\.[Ss]\\.',
    '\\s*[Uu]\\.[Ss]\\.[Aa]\\.',
    '\\s*[Vv][Ss]\\.'
  ];

  constructor(
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.itemFilter = this.itemFilter.bind(this);
    this.checkIsAddedItems();

    if (this.itemsObservable) {
      this.itemsObservable.subscribe(value => {
        if (!this.isUpArrow) {
          switch (this.type.toLowerCase()) {
            case 'phrase':
              this.wordGroupItems = value['phrases'];
              break;
            case 'passage':
              this.wordGroupItems = value['passages'];
              break;
            case 'sentence':
              this.wordGroupItems = value['sentences'];
          }
        }

        this.checkIsAddedItems();
      });
    }

    this.wordGroupItems = this.wordGroupItems;
    // .sort((a, b) => a[this.type] === b[this.type] ? a[this.type] > b[this.type] ? -1 : 1 : 0)

    // .sort((a, b) => a[this.type].localeCompare(b[this.type]))

    // .sort((a: any, b: any) => {
    //   if (a[this.type.toLowerCase()].toLowerCase() < b[this.type.toLowerCase()].toLowerCase()) { return -1; }
    //   if (a[this.type.toLowerCase()].toLowerCase() > b[this.type.toLowerCase()].toLowerCase()) { return 1; }
    //   return 0;

    // });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.otherItems) {
      this.checkIsAddedItems();
    }
  }

  addEvent(itemId: string) {
    this.add.emit(itemId);
  }

  validateWordGroup(wordGroup: string, contentType: string = ''): string {
    let wordGroupTestValue  = wordGroup;

    // ignore abbreviations
    for (let i = 0; i < this.abbreviations.length; i++) {
      const regex = new RegExp(`\\b${this.abbreviations[i]}`, 'g');
      wordGroupTestValue = wordGroupTestValue.replace(regex, '');
    }

    // If the word group has no terminal punc, after stripping abbrv, and no quotes, it is a phrase
    if ((wordGroupTestValue.match(/[\?\.!]/g) || []).length === 0
        && (wordGroupTestValue.match(/"/g) || []).length === 0) {
      // it is not a phrase if the type is sentence, or it has a period at the end unless the type is phrase
      if (contentType === 'Phrase' || contentType !== 'Sentence' || wordGroup.lastIndexOf('.') !== wordGroup.length - 1) {
        return this.wordGroupType.phrase;
      }
    }

    // Assure the string ends with a terminal point, or terminal point followed by a quotation
    let puncNum = wordGroup.match(/(\?|\.|!|\?"|\."|!")$/g);
    const endsInPunc = (wordGroup.match(/(\?|\.|!|\?"|\."|!")$/g) || []).length === 1;

    // //Remove all content within quotations, and check for 1 or less terminal punctuation
    const oneTerminal = (wordGroupTestValue.replace(/".*"/g, '').match(/[\?\.!]/g) || []).length <= 1;

    // If the PSP ends in a terminal, and has only terminal, after stripping quotation terminals and approved abbrvs, it is a sentence
    if (endsInPunc && oneTerminal) {
      return this.wordGroupType.sentence;
    }

    return this.wordGroupType.passage;

  }
  removeEvent(itemId: string) {

    const dialogRef = this.dialog.open(DeleteItemDialogComponent, {
      height: '110px',
      width: '200px',
      panelClass: 'custom-confirm-dialog',

      data: {
        type: this.type,
         workbookId: this.workbookId

      }
    });
    dialogRef.componentInstance.functions = {
      deleteContent: () => {
        const index = this.wordGroupItems.findIndex(item => item._id === itemId);
        if (this.wordGroupItems[index].isEditing) {
          return this.wordGroupItems[index].isEditing = false;
        }

        if (this.isUpArrow) {
          const index = this.wordGroupItems.findIndex(item => item._id === itemId);
          // this.wordGroupItems.splice(index, 1);
        }

        this.remove.emit(itemId);
      }

    };
  }

  updateEvent(item: any) {
    const index = this.wordGroupItems.findIndex(wordGroupItem => wordGroupItem._id === item._id);
    this.wordGroupItems[index].isEditing = false;
    this.update.emit({itemId: item._id, value: item[this.type.toLowerCase()]});
  }

  itemFilter(item: any) {
    if (!this.searchTerm || this.searchTerm === '') {
      return  true;
    }

    return item[this.type.toLowerCase()].toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0;
  }

  beginEdit(itemId: string) {
    const index = this.wordGroupItems.findIndex(item => item._id === itemId);
    // this.wordGroupItems[index].isEditing = true;

    const dialogRef = this.dialog.open(EditItemDialogComponent, {
      width: '450px',
      panelClass: 'type-edit-modalbox',
      data: { item: this.wordGroupItems[index], type: this.type},

    });

    dialogRef.componentInstance.functions = {
      validateContent: (value, type) => {
        const match = this.validateWordGroup(value, type);
        return {mismatchStatus: (match === type), type: match};
      },
      updateContent: (item) => {
          this.update.emit({itemId: item.id, value: item.value});
      }

    };


  }
  addNewContent(type) {
    const dialogRef = this.dialog.open(AddItemDialogComponent, {
      width: '450px',
      panelClass: 'type-edit-modalbox',
      disableClose: true,
      data: {
        type: type,
         workbookId: this.workbookId,
        mismatch: this.mismatch


      }
    });
    dialogRef.componentInstance.functions = {
      addContent: (value) => {
        value = value.trim();
        const match = this.validateWordGroup(value, type);
        if (match === type) {
          const content: any = {content: value, type: type};
          this.addContentEvent.emit(content);
          return {mismatchStatus: false, type: match};

        } else { return {mismatchStatus: true, type: match}; }
      }

    };

    // if (this.model) {
    // }
  }

  moveContent(destination: string, mismatchedLocation: string) {
    const contentToMove: any = {content: this.model, type: destination};
    this.addContentEvent.emit(contentToMove);
    this.clearMismatchEvent.emit(mismatchedLocation);
  }

  closeNewCard(type) {
    this.model = '';
    this.closeNewCardEvent.emit(true);
    this.clearMismatchEvent.emit(type);
  }

  reorderItems($event) {
    let moveFiles = (arr, fromIndex, toIndex) => {
      let element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    };

    this.wordGroupItems = JSON.parse(JSON.stringify(this.wordGroupItems));
    if ($event.container.id === $event.previousContainer.id) {
     this.wordGroupItems =  moveFiles($event.container.data, $event.previousIndex, $event.currentIndex);
      for (let i = 0; i < this.wordGroupItems.length; i++) {
        this.wordGroupItems[i] = JSON.parse(JSON.stringify(this.wordGroupItems[i]));
        this.wordGroupItems[i]["order"] = i;
      }
      this.reorder.emit(JSON.parse(JSON.stringify(this.wordGroupItems)));

    } else {
      transferArrayItem($event.previousContainer.data, $event.container.data, $event.previousIndex, $event.currentIndex);
    }
  }
  sortBy(prop: string): any[] {
    return this.wordGroupItems.sort((a, b) => {
      let a1 = JSON.parse(JSON.stringify(a));
      let b1 = JSON.parse(JSON.stringify(b));
      if (a1[prop] < b1[prop]) {
        return -1;
      }

      if (a1[prop] > b1[prop]) {
        return 1;
      }

      return 0;
    });
  }

  checkIsAddedItems() {
    this.wordGroupItems = this.wordGroupItems.map(item => {
      if (this.isUpArrow) {
        item.isAdded = this.otherItems ? this.otherItems.findIndex(oi => oi[`${this.type.toLowerCase()}Id`] === item._id || oi._id === item._id) >= 0 : false;
      } else {
        item.isAdded = this.otherItems ? this.otherItems.findIndex(oi => oi._id === item[`${this.type.toLowerCase()}Id` || '_id']) >= 0 : false;
      }

      return item;
    });
  }

  splitMultiLineText(text: string): string[] {
    if (!text) {
      return [''];
    }
    return text.split("\n");
  }
}


@Component({
  selector: 'app-edit-item-dialog',
  templateUrl: './html/edit-item-dialog.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './sentences-and-more.scss'
  ]
})

export class EditItemDialogComponent implements OnInit {
  functions;
  public keys: any;
  public value: string;
  public mismatch = false;
  public type: string;
  constructor(public dialogRef: MatDialogRef<EditItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
) {
}

  ngOnInit() {
    this.keys = Object.keys(this.data);
    this.type = this.data.type;
    this.value = this.data.item[this.type.toLowerCase()];
  }
  edit(event: any) {
    this.value = event.target.value;
  }

  save() {
    const results = this.functions.validateContent(this.value, this.type);
    if (results.mismatchStatus) {
      // this.data.item[this.type.toLowerCase()] = this.value;
      this.functions.updateContent({
        id: this.data.item._id,
        value: this.value
      });
      this.dialogRef.close();

    } else {
      this.mismatch = true;
    }

  }

  close() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-add-item-dialog',
  templateUrl: './html/add-item-dialog.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './sentences-and-more.scss'
  ]
})


export class AddItemDialogComponent implements OnInit {
  functions;
  public keys: any;
  public value = '';
  public mismatchStatus = false;
  public mismatch: string;
  constructor(public dialogRef: MatDialogRef<AddItemDialogComponent>,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
) {
}

  ngOnInit() {
  }

  edit(event: any) {
    this.value = event.target.value;
    this.mismatchStatus = false;
  }

  save() {
    const mismatch = this.functions.addContent(this.value);
    this.mismatchStatus = mismatch.mismatchStatus;
    this.mismatch = mismatch.type;
    if (this.mismatchStatus === false) {
      this.dialogRef.close();
    }
    setTimeout(() => {
    }, 1000);
  }

  close() {
    this.dialogRef.close();
    this.route.navigate([`/workbook-builder/${this.data.workbookId}`, {isFromLibrary: false, selectedTabIndex: 1}]);

  }
}



@Component({
  selector: 'app-delete-item-dialog',
  templateUrl: './html/delete-item-dialog.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './sentences-and-more.scss'
  ],
  styles: [
    `:host {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }`
  ]
})


export class DeleteItemDialogComponent implements OnInit {
  functions;
  public keys: any;
  public value: string;

  constructor(public dialogRef: MatDialogRef<DeleteItemDialogComponent>,
    private route: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
) {
}

  ngOnInit() {
  }

  edit(event: any) {
    this.value = event.target.value;
  }

  save() {
    this.functions.deleteContent();
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
    // this.route.navigate([`/workbook-builder/${this.data.workbookId}`, {isFromLibrary: false, selectedTabIndex: 1}])

  }
}
