import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_DIR, AppConfig } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()

export class FilesService {
  constructor(private http: HttpClient) {}

  getFile(id: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/file/${id}`)
        .subscribe(
          (files: any) => observer.next(files.data),
          error => {
            throw error;
          }
        );
    });
  }

  getManyFiles(userId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/file/user/${userId}`)
        .subscribe(
          (files: any) => observer.next(files.data),
          error => {
            throw error;
          }
        );
    });
  }

  getManyWorkbookFiles(workbookId: string) {
    return new Observable(observer => {
      this.http
        .get(`${API_DIR}/workbookFile/workbook/${workbookId}`)
        .subscribe(
          (workbookFiles: any) => {
            return observer.next(workbookFiles.data);
          },
          error => {
            throw error;
          }
        );
    });
  }

  createFile(newFile: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/file`, newFile)
        .subscribe(
          (file: any) => {
            observer.next(file.data);

          },
          error => {
            throw error;
          }
        );
    });
  }

  createManyFiles(newFile: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/file/multi`, newFile)
        .subscribe(
          (file: any) => {

            observer.next(file.data);

          },
          error => {
            throw error;
          }
        );
    });
  }


  createWorkbookFile(newWorkbookFile: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/workbookFile`, newWorkbookFile)
        .subscribe(
          (workbookFile: any) => {
            observer.next(workbookFile.data);
          },
          error => {
            throw error;
          }
        );
    });
  }
  createManyWorkbookFiles(newFile: any) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/workbookFile/multi`, newFile)
        .subscribe(
          (file: any) => {
            observer.next(file.data);
          },
          error => {
            throw error;
          }
        );
    });
  }

  renameFile(fileId: string, name: string) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/file/rename/${fileId}/${name}`, {})
        .subscribe(
          (file: any) => {
            observer.next(file.data);
          },
          error => {
            throw error;
          }
        );
    });
  }

  updateWorkbookFile(workbookFileId: string, updatedFile: any) {
    return new Observable(observer => {
      this.http
        .put(`${API_DIR}/workbookFile/${workbookFileId}`, updatedFile)
        .subscribe(
          (file: any) => {
            observer.next(file.data);
          },
          error => {
            throw error;
          }
        );
    });
  }

  deleteFile(fileId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/file/${fileId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }

  deleteWorkbookFile(collectionId: string, itemId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/workbookFile/${collectionId}/${itemId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }

  deleteManyWorkbookFiles(fileId: string) {
    return new Observable(observer => {
      this.http
        .delete(`${API_DIR}/workbookFile/file/${fileId}`)
        .subscribe(
          () => observer.next(),
          error => {
            throw error;
          }
        );
    });
  }
}
