import { Store } from '@ngrx/store';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { WordFilterComponent } from './word-filter.component';
import { WordsService } from '../../../../services/words.service';
import { take, takeUntil } from 'rxjs/operators';
import { connect } from 'net';
import { selectGetPairings } from '../../../../store';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-word-pairings',
  templateUrl: './html/word-filter-pairings.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    '../workbook-builder.scss',
    './word-filter.scss'
  ]
})
export class WordFilterPairingsComponent implements OnInit, OnDestroy {
  @Input() filter: any;
  @Input() filters: any[] = [];
  @Output() updateQuery: EventEmitter<any> = new EventEmitter<any>();

  firstPairingValues: any[] = [];
  lastPairingValues: any[] = [];
  basicPairingValues: any[] = [];

  firstPairings: any;
  lastPairings: any;
  basicPairings: any;

  pairings: any[];
  firstOptions: any[] = [];
  lastOptions: any[] = [];
  basicOptions: any[] = [];
  themehighlightcolor: string;
  private unsubscribe$: Subject<void> = new Subject();

  constructor (private wordService: WordsService, private store: Store) {}

  ngOnInit (): void {
    // this.wordService
    //   .getPairings()
    //   .pipe(
    //     take(1)
    //   )
    //   .subscribe((pairings: any[]) => {
    //     this.pairings = this.filterNumbers(pairings);
    //     this.firstPairings = pairings.slice();
    //     this.lastPairings = pairings.slice();
    //     this.basicPairings = pairings.slice();

    //     this.buildCheckBoxes();
    //   });
    this.store
      .select(selectGetPairings)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(pairings => {
        if (!pairings) {
          this.pairings = null;
          return;
        }
        this.pairings = JSON.parse(JSON.stringify(this.filterNumbers(pairings)));
        this.firstPairings = pairings.slice();
        this.lastPairings = pairings.slice();
        this.basicPairings = pairings.slice();

        this.buildCheckBoxes();
      });

    if (
      localStorage.getItem('themehighlightcolor') !== null ||
      localStorage.getItem('themehighlightcolor') !== undefined
    ) {
      this.themehighlightcolor = localStorage.getItem('themehighlightcolor');
    } else {
      this.themehighlightcolor = 'theme1';
    }
  }

  ngOnDestroy () {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildCheckBoxes () {
    if (this.filter.currentVal?.firstPairing?.length > 0) {
      this.filter.currentVal.firstPairing.forEach(pairing => {
        this.pairings.forEach(letter => {
          letter.examples.forEach(example => {
            if (example.value === pairing) {
              if (!this.firstPairingValues?.includes(example)) {
                this.firstPairingValues.push(example);
              }
            }
          });
        });
      });
    }
    if (this.filter.currentVal?.lastPairing?.length > 0) {
      this.filter.currentVal.lastPairing.forEach(pairing => {
        this.pairings.forEach(letter => {
          letter.examples.forEach(example => {
            if (example.value === pairing) {
              if (!this.lastPairingValues?.includes(example)) {
                this.lastPairingValues.push(example);
              }
            }
          });
        });
      });
    }
    if (this.filter.currentVal?.basicPairing?.length > 0) {
      this.filter.currentVal.basicPairing.forEach(pairing => {
        this.pairings.forEach(letter => {
          letter.examples.forEach(example => {
            if (example.value === pairing) {
              if (!this.basicPairingValues?.includes(example)) {
                this.basicPairingValues.push(example);
              }
            }
          });
        });
      });
    }
  }

  boxChecked (index: number, option: any, optionIndex: number) {
    switch (index) {
      case 0: // first pairings
        const firstPairingValIndex = this.firstPairingValues.findIndex(({ value }) => value === option.value);
        if (firstPairingValIndex >= 0) {
          this.firstPairingValues.splice(firstPairingValIndex, 1);
          this.firstOptions[optionIndex].checked = false;
        } else {
          let op = { ...option };
          op.checked = true;
          this.firstOptions[optionIndex].checked = true;
          this.firstPairingValues.push(op);
        }
        break;
      case 1:
        const lastPairingValIndex = this.lastPairingValues.findIndex(({ value }) => value === option.value);
        if (lastPairingValIndex >= 0) {
          this.lastPairingValues.splice(lastPairingValIndex, 1);
          this.lastOptions[optionIndex].checked = false;
        } else {
          let op = { ...option };
          op.checked = true;
          this.lastOptions[optionIndex].checked = true;
          this.lastPairingValues.push(op);
        }
        break;
      case 2:
        const basicPairingValIndex = this.basicPairingValues.findIndex(({ value }) => value === option.value);
        if (basicPairingValIndex >= 0) {
          this.basicPairingValues.splice(basicPairingValIndex, 1);
          this.basicOptions[optionIndex].checked = false;
        } else {
          let op = { ...option };
          op.checked = true;
          this.basicOptions[optionIndex].checked = true;
          this.basicPairingValues.push(op);
        }
        break;
    }
    this.updateQueryEvent();
  }

  updateQueryEvent () {
    const filteredFirstPairing = this.firstPairingValues.map(
      pairing => pairing.value
    );
    const filteredLastPairing = this.lastPairingValues.map(
      pairing => pairing.value
    );
    const filteredBasicPairing = this.basicPairingValues.map(
      pairing => pairing.value
    );
    this.updateQuery.emit({
      soundPairing: {
        firstPairing: filteredFirstPairing,
        lastPairing: filteredLastPairing,
        basicPairing: filteredBasicPairing
      }
    });
  }

  setCurrentOptions ($event, examples, index) {
    this.buildCheckBoxes();
    if ($event.isUserInput) {
      switch (index) {
        case 0:
          this.firstOptions = examples !== null ? examples.slice() : [];
          this.firstOptions = this.firstOptions.map(option => {
            return {
              ...option,
              checked: !!this.firstPairingValues.find(
                ({ value }) => value === option.value
              )
            };
          });
          break;
        case 1:
          this.lastOptions = examples !== null ? examples.slice() : [];
          this.lastOptions = this.lastOptions.map(option => {
            return {
              ...option,
              checked: !!this.lastPairingValues.find(
                ({ value }) => value === option.value
              )
            };
          });
          break;
        case 2:
          this.basicOptions = examples !== null ? examples.slice() : [];
          this.basicOptions = this.basicOptions.map(option => {
            return {
              ...option,
              checked: !!this.basicPairingValues.find(
                ({ value }) => value === option.value
              )
            };
          });
          break;
      }
    }
  }

  filterNumbers (pairings): any[] {
    const reg = new RegExp(/[^0-9-_+']/);
    const filteredPairings = pairings.filter(pairing => {
      return reg.test(pairing.letter);
    });
    return filteredPairings;
  }

  clearPairingList (list: string) {
    switch (list) {
      case 'firstPairingValues':
        this.firstPairingValues = [];
        this.firstOptions = this.firstOptions.map(op => {
          op.checked = false;

          return op;
        });
        break;
      case 'lastPairingValues':
        this.lastPairingValues = [];
        this.lastOptions = this.lastOptions.map(op => {
          op.checked = false;

          return op;
        });
        break;
      case 'basicPairingValues':
        this.basicPairingValues = [];
        this.basicOptions = this.basicOptions.map(op => {
          op.checked = false;

          return op;
        });
        break;
    }
    this.updateQueryEvent();

  }
}
