import {ErrorHandler, Inject, Injectable, InjectionToken} from '@angular/core';
import * as Rollbar from 'rollbar';
import {ROLLBAR_API_KEY, AppConfig} from '../../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import { AuthService } from '../../../core/services/auth.service';
import { Router } from '@angular/router';

const rollbarConfig = {
  accessToken: ROLLBAR_API_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    person: {
      id: !localStorage.profile ? "" : JSON.parse(localStorage.profile).email,
      email: !localStorage.profile ? "" : JSON.parse(localStorage.profile).email,
    },
    environment: AppConfig.environment,
  }
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    console.log("Error: ", err);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(err.message)) {
      window.location.reload();
      return;
    }

    if (err.status === 401) {
      return;
    }

    if (err.message
      && (
          err.message.toLowerCase().indexOf('access_token') > -1
          || err.message.toLowerCase().indexOf('cannot read properties of') > -1
          || err.message.toLowerCase().indexOf('https://auth0.whizzimo.com') > -1
          || err.message.toLowerCase().indexOf('_pdfjsLib') > -1
          || err.message.toLowerCase().indexOf('en.json') > -1
          || err.message.toLowerCase().indexOf('auth error') > -1
        )
    ) {
      return;
    }
    // @ts-ignore
    this.rollbar.error(...this.sanitizedError(err.originalError || err));
  }

  private sanitizedError(error: any) {
    if (error instanceof Error || error instanceof String) {
      return [error];
    } else if (error instanceof HttpErrorResponse) {
      return [error.message, error];
    } else {
      return ['Error', error];
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
