import { Component, OnInit, ViewChild, AfterViewInit, Renderer2, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { WordlistService } from '../../core/services/wordlist.service';
import { ApiService } from '../../core/services/api.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './html/words.html',
  styleUrls: [
    './words.scss',
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss'
  ]
})
export class WordsComponent implements OnInit, AfterViewInit, OnDestroy {

  public tileSearch: string;
  public removedTiles: any = [];
  private ownerKey: string;
  public titleTog = true;
  public descTog = true;
  public wordlist: any = [];
  public workbook: any = {
        'name': '',
        'ownerKey': 'WhizzimoAcademy:643',
        'tiles': [
          'tiles.bv.a',
          'tiles.bv.e',
          'tiles.bv.i',
          'tiles.bv.o',
          'tiles.bv.u',
          'tiles.bv.y',
          'tiles.bc.b',
          'tiles.bc.c',
          'tiles.bc.f'
        ],
        'filters': {
          'isPhonetic': true,
          'isNonsense': false,
          'nLetters': {
            'gte': 1,
            'lte': 5
          },
          'nSyllables': {
            'gte': 1,
            'lte': 5
          }
        },
        'desc': ''
    };
  public filters: any = [
        'isPhonetic',
        'isNonsense',
        'nLetters',
        'nSyllables'
    ];
    public phonetic: any = 0;
    public sight: any;
    private subscriptions = new Subscription();


  constructor(
    private _api: ApiService,
    private _wordlist: WordlistService,
    // private _tiles: TilesService,
    private el: ElementRef,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router

  ) {
    this.subscriptions.add(
      this.route.params
        .subscribe((p) => {
          if (p.filter === 'words') {
            this.filters.push('exactWords');
          } else if (p.filter === 'tiles') {
            this.filters.push('tiles');
          }
        })
    );

  }

  @ViewChild('tileBox', {static: false}) private tileBox: ElementRef;

  ngAfterViewInit() {
    // this.workbook.tiles.forEach((tile) => {
    //   let parts = tile.split('.');
    //   let tileText = this.renderer.createText(parts[2]);
    //   let tileDiv = this.renderer.createElement('div')
    //   this.renderer.setAttribute(tileDiv, 'class', 'tile')
    //   this.renderer.setAttribute(tileDiv, 'cdkDrag', 'true')
    //   this.renderer.appendChild(tileDiv,  tileText);
    //   this.renderer.appendChild(this.tileBox.nativeElement, tile);
    // })

  }

  ngOnInit() {
    // this.workbook.tiles.forEach((tile) => {
    //   let parts = tile.split('.');
    //   let tileText = this.document.createTextNode(parts[1]);
    //   let tile = this.document.createElement('div')
    //       .setAttribute('class', 'tile')
    //       .setAttribute('cdkDrag')
    //       .appendChild(tileText);
    //   tileBox.appendChild(tile);
    // })

  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }
  
  search() {
    this.workbook.name = this.workbook.name === '' ? 'Untitled Workbook' : this.workbook.name;
    this._wordlist.getWordLib(this.workbook.filters)
    .subscribe((data) => {
        this.wordlist = data['data'];
      });
  }

  saveAndExit() {
    if (this.wordlist.length > 0) {
      this._api.mkWorkBook(this.workbook)
      .subscribe((data) => {
        // this._wordlist.wordlist = this.wordlist;
        return this.router.navigate(['/my-curriculum']);

      });

    }
  }

  deleteWordlist() {
    if (this.wordlist.length > 0) {
      this.wordlist = [];
    }
  }

  drop(event: CdkDragDrop<string[]>) {
     if (event.previousContainer === event.container) {
       moveItemInArray(this.workbook.tiles, event.previousIndex, event.currentIndex);
     } else {
       transferArrayItem(this.workbook.tiles,
                      this.removedTiles,
                       event.previousIndex,
                       event.currentIndex);
        this.removedTiles = [];
     }
  }

  exactWords(value: string) {
    const words = [];
    value.split(',').forEach((word) => {

      words.push(word.trim());
    });

    this.workbook.filters['exactWords'] = words;
  }

  toggleLetMost(toggle) {
    switch (toggle) {
      case 'add':
        this.workbook.filters.nLetters.gte += 1;
        break;
      case 'sub':
      if (this.workbook.filters.nLetters.gte > 0) {
        this.workbook.filters.nLetters.gte -= 1;
      }
      break;

    }

  }
  toggleLetLeast(toggle) {
    switch (toggle) {
      case 'add':
        this.workbook.filters.nLetters.lte += 1;
        break;
      case 'sub':
      if (this.workbook.filters.nLetters.lte > 0) {
        this.workbook.filters.nLetters.lte -= 1;
      }
      break;

    }

  }



}
