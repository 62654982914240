// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `index.ts`, but if you do
// `ng build --env=prod` then `index.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const AppConfig = {
  production: false,
  environment: 'DEVELOPMENT',
  auth0: {
    domain: 'auth0.whizzimo.com',
    clientId: 'MX2QYiGEd416fvtTL8T94s08Jti5zbzB',
    callbackURL: 'https://eric.whizzimo.com/',
    Options: {
      allowSignUp: false,
      rememberLastLogin: true,
      theme: {
        logo: '/assets/img/Circle Purple W Logo.png',
        primaryColor: '#481E9F'
      },
      forgotPasswordLink: '#/passwordreset',
      auth: {
        autoParseHash: true,
        redirect: true,
        redirectUrl: 'https://eric.whizzimo.com/',
        responseType: 'id_token token',
        audience: 'https://whizzimoapp.auth0.com/userinfo',
        params: {
          scope: 'openid profile email'
        },
        sso: true
      },
      languageDictionary: {
        title: 'Whizzimo'
      },
      autoclose: true,
      oidcConformant: true
    }
  },
  files: {
    root: 'https://storage.googleapis.com/whizzimo-dev.appspot.com/'
  }
};
export const PUBLISHABLE_KEY = 'pk_test_3plF76arhkygGMgwCEerThpa';
export const APP_ID = 'asn7h2bb';
export const API_DIR = 'https://dev-server.whizzimo.com';
export const AUDIO_DIR = 'https://storage.googleapis.com/audio-files-whizzimo/';
export const WORD_AUDIO_DIR = 'https://storage.googleapis.com/audio-files-whizzimo/words/';
export const BARTON_USER = 'WhizzimoAcademy:145';
export const _variable_images = 'assets/img/theme1/';
export const GCLOUD_KEYFILE_URI = "./WhizzimoDevGCloudKey.json";
export const isAlt = false;
export const BUG_SNAG_API_KEY = '5d17e995c8a428381b97af6509c5107c';
export const AIRBRAKE_PROJECT_ID = 510886;
export const AIRBRAKE_API_KEY = 'b216d460f1efcac5a40ee2c674e1cb3f';
export const ROLLBAR_API_KEY = 'fb699603fd424d6fb85700fd724f863d';
export const POSTHOG_API_KEY = 'phc_3iLzWYPrYOaL6vpCzyKoNnXFg5SM0yBkyhYiveMEdvF';
export const MIXPANEL_TOKEN = 'f2c247754864380affb965fd16f52886';
