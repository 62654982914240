import {Component, Inject, OnInit} from '@angular/core';
import { RegistrationService } from '../core/services/registration.service';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {UtilityService} from '../core/services/utility.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './html/registration.html',
  styleUrls: ['../assets/css/main.css',
              './registration.component.scss',
              '../assets/scss/fontawesome.scss',
              '../assets/scss/brands.scss',
              '../assets/scss/regular.scss',
              '../assets/scss/solid.scss']
})


export class RegistrationComponent implements OnInit {
  public errMsg = '';
  public fname: string;
  public lname: string;
  public email: string;
  public password: string;
  public confirmedPass: string;
  public contact = "";
  public source: string;
  public legal: boolean;
  public submitValue = 'Submit';
  // pass validation variables
  public lower_case = false;
  public upper_case = false;
  public numeric = false;
  public special = false;
  public minLength = false;
  public new: any;
  public pass: any;
  public isLoading = false;
  infoDialog: any;
  tos: any;
  public confirmedEmail: string;

  constructor(private _reg: RegistrationService, private router: Router, private dialog: MatDialog, private utilityService: UtilityService) {}

  ngOnInit() {}

  validatePass(event: any) {
      const pass = event.target.value;

          if (pass.length >= 8) {
              this.minLength = true;
          } else {
              this.minLength = false;
          }
          if (pass.search(/[A-Z]/g) >= 0) {
              this.upper_case = true;
          } else {
              this.upper_case = false;
          }
          if (pass.search(/[a-z]/g) >= 0) {
              this.lower_case = true;
          } else {
              this.lower_case = false;
          }

          if (pass.search(/[0-9]/g) >= 0) {
              this.numeric = true;
          } else {
              this.numeric = false;
          }

          if ((pass.search(/[- \/ \\^$!@#%*+?.()|[ \] {}]/g) >= 0)) {
              this.special = true;
          } else {
              this.special = false;
          }

  }
  validateConfirmPass(event: any) {
    const pass = event.target.value;
    if (pass === this.password) {
      // this.confirmedPass =
    }
  }
  keyEmail(event: any, form: any) {
    const reg = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  }


  submit() {
    this.isLoading = true;
    this.submitValue = 'Please wait...';
    // if(!valid){
    //   return;
    // }
    const pack = {
          "code": "",
          "codeDesc": null,
          "contact": this.contact,
          "email": this.email.toLowerCase(),
          "firstName": this.fname,
          "lastName": this.lname,
          "legalCheck": this.legal,
          "password": this.password,
          "source": this.source,
          "plan": "Free",
          "promo": "",
          "role": "Teacher"
        };
        this._reg.register(pack)
        .subscribe(
          data => {
            this._reg.authData = data["data"];
            // let course = {
            //       "firstname": json.firstname,
            //       "teacherKey": this.authData.,
            //       "notes": "string",
            //       "courseType": "string"
            // }

            this.router.navigate(['/login']);


          },
          (error: any) => {
            this.errMsg = error.error.description;
            this.submitValue = 'Submit';

          });

        this.isLoading = false;

  }
  openDialog(isTerms: boolean): void {
    const header = isTerms ? 'Terms of Service' : 'Privacy Policy';
    const body = isTerms ? UtilityService.termsOfUse : UtilityService.privacyPolicy;
    this.infoDialog = this.dialog.open(RegistrationInfoDialogComponent, {
      panelClass: 'activity-modalbox',
      data: {
        header: header,
        body: body
      },
      width: '550px'
    });

    this.infoDialog.componentInstance.functions = {
      closeDialog: (activity) => this.infoDialog.close()
    };
  }

  validateConfirmEmail($event: KeyboardEvent) {
    // validate that email === confirmemail
    if (this.email === this.confirmedEmail) {
    } 
  }
}

@Component({
  selector: 'app-registration-info-menu',
  template: `
    <div>
      <h2 mat-dialog-title>{{data.header}}</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <div style="margin: 20px" [innerHTML]="data.body"></div>
    </mat-dialog-content>
  `
})
export class RegistrationInfoDialogComponent {
  functions;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {}

  closeActivityDialog(activity, data) {
    this.functions.closeDialog();
  }
}
