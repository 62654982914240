import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {Subject, Subscription} from 'rxjs';
import {WorkbooksService} from '../../services/workbooks.service';
import {WorkbookMenuComponent} from './workbooks.component';

@Component({
  selector: 'app-workbook-library-element',
  templateUrl: 'html/workbook-library.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss'
  ]
})

export class WorkbookLibraryComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private workbooks: any[] = [];
  public filteredWorkbooks: any[] = [];
  @Output() addWorkbook: EventEmitter<any> = new EventEmitter<any>(false);
  addedWorkbookSubject: Subject<any> = new Subject<any>();

  constructor(
    private dialog: MatDialog,
    private workbookService: WorkbooksService
  ) {}

  ngOnInit() {
    this.workbookService
      .getLibraryWorkbooks()
      .subscribe((workbooks: any[]) => {
        this.workbooks = workbooks;
        this.filteredWorkbooks = workbooks.slice();
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  openDialog(currentWorkbook) {
    const dialogRef = this.dialog.open(WorkbookLibraryMenuComponent, {
      data: {workbook: currentWorkbook},
      width: '350px'
    });

    dialogRef.componentInstance.functions = {
      addWorkbook: (workbook) => {
        dialogRef.close();
        this.addWorkbook.emit(workbook);
      }
    };
  }

  search($event) {
    if ($event.target.value === '') {
      return this.filteredWorkbooks = this.workbooks.slice();
    }

    this.filteredWorkbooks = this.workbooks.filter(workbook => workbook.name.toLowerCase().indexOf($event.target.value.toLowerCase()) >= 0);
  }


}

@Component({
  selector: 'app-workbook-library-menu',
  templateUrl: './html/workbook-library-menu.html'
})
export class WorkbookLibraryMenuComponent {
  functions;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  addWorkbook(workbook) {
    this.functions.addWorkbook(workbook);
  }
}
