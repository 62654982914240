import { Store } from '@ngrx/store';
import {Injectable, ComponentFactoryResolver, Renderer2, RendererFactory2, ViewContainerRef, OnDestroy} from '@angular/core';
import { SettingsService } from '../services/new-settings.service';
import { ListenerService } from '../services/listener.service';

import { HostListener } from '@angular/core';
import {BehaviorSubject, fromEvent, Observable, of, Subject, Subscription} from 'rxjs';
import { switchMap, takeUntil, pairwise, takeWhile, map, distinctUntilChanged, debounceTime, tap } from 'rxjs/operators';

import { TextBoxComponent } from '../../activities/activity-header/text-box/text-box.component';
import { UserService } from './user.service';
import { DataService } from './data.service';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import { getCurrentSetting, selectCurrentTheme } from '../store';
import { SetActivitySelectedTool } from '../store/activity/activity.actions';
import { NavbarListenerService } from './navbar-listener-service..service';
import {ScrollDispatcher } from '@angular/cdk/scrolling';
import {AsyncPipe} from '@angular/common';
@Injectable({
  providedIn: 'root'
})

export class ActivityHeaderService implements OnDestroy {
  private static isMouseOverDeadzone: boolean;

  static body: any;
  static activityBody: any;
  public scrollTop = 0;
  private subscriptions: any[] = [];
  public drawables: Array<any> = [];
  public drawablesSubject = new BehaviorSubject([]);
  public activeActivity: string;
  public drawables$ = this.drawablesSubject.asObservable()
    .pipe(
      distinctUntilChanged((a, b) => {
        try {
          return a && b && JSON.stringify(a) === JSON.stringify(b);
        } catch (error) {
          return true;
        }
      }),
      map((items: any) => {
        const activity = this.router.url?.split('/')[2]?.split('-').join(' ');
        if (activity) {
          this.activeActivity = activity;
        }
        let newItems;
        if (items?.length) {
          newItems = items.filter(item => item.name === activity || item.name === 'activity-header');
        } else {
          newItems = [];
        }
        return newItems;
      })
    );
  public undoQueue: Array<any> = [];
  public lineWidth = 3;
  public eraserWidth = 40;
  private lineCap: String = 'round';
  public strokeStyle = '#000';
  public userSettings: any;
  private globalCompositeOperation: String = 'source-over';
  private navbarToggle: Subject<any> = new Subject<any>();
  textFont: Subject<string> = new Subject();
  private renderer: Renderer2;
  public positions: Array<any> = [];
  public colors = ['black', 'blue', 'green', 'pink'];
  public color = '#000';
  public tool = 'draw';
  public activeInitTool: 'text' | 'draw' = 'text';
  public activityTool: string;
  public lastClick: number;
  public pdfCanvas: any;
  private isMouseOverHeader: boolean;
  public toggleText: Subject<boolean> = new Subject();
  public togglePadSubject: Subject<boolean> = new Subject();
  private unsubscribe$: Subject<void> = new Subject();
  private isTrashcanClicked = false;
  public activityName: string;
  public textboxRef;
  public toolSubject = new BehaviorSubject(this.tool);
  public tool$ = this.toolSubject.asObservable();
  fontSize: number;
  constructor(
    private resolver: ComponentFactoryResolver,
    private settingsService: SettingsService,
    private userService: UserService,
    rendererFactory: RendererFactory2,
    private listenerService: ListenerService,
    private router: Router,
    private store: Store,
    private dataService: DataService,
    private navbarListenerService: NavbarListenerService,
    private window: Window,
    private scroll: ScrollDispatcher,
    private async: AsyncPipe
) {
  this.renderer = rendererFactory.createRenderer(null, null);
    if (!this.userService.auth0UserInfo) {
      return;
    }
    const userId = JSON.parse(localStorage.profile).user_metadata.uid;

    // this.subscriptions.push(this.settingsService.activitySettingsSubjet$.subscribe((settings: any) => {
    //   this.userSettings = settings;
    //   this.userSettings = JSON.parse(JSON.stringify(setting));

    // }));
    //
    //
    this.store.select(getCurrentSetting).pipe(takeUntil(this.unsubscribe$)).subscribe(setting => {
      if (!setting) {
        this.userSettings = null;
        return;
      }
      this.userSettings = JSON.parse(JSON.stringify(setting));
      TextBoxComponent.fontSize = this.userSettings.fontSize;
      this.fontSize = this.userSettings.fontSize;
    });
    this.subscriptions['general'] = [];
    this.subscriptions['general'].push(this.listenerService.listener().subscribe((data: any) => {
      if (data.canvas) {
        this.pdfCanvas = data.canvas;
      } else if (data === 'toggleText') {
        this.listenerService.callback('toggleText 2');

        setTimeout(() => {
          this.toggleTool('text');

        }, 2000);
      }
    }));
    const interval = setInterval(() => {
      if (this.settingsService.activitySettings) {
        this.userSettings = this.settingsService.activitySettings;
        clearInterval(interval);
      }
    }, 150);

    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const activity = event.url?.split('/')[2]?.split('-').join(' ');
        if (activity) {
          this.activeActivity = activity;
        }
      }
    });
  }
  showMissingTilesModalSubject: Subject<any> = new Subject<boolean>();
  showMissingTilesModal$ = this.showMissingTilesModalSubject.asObservable();

  static updateOverDeadzone(isOverDeadZone: boolean) {
    // if (!isOverDeadZone) {
    // }
    ActivityHeaderService.isMouseOverDeadzone = isOverDeadZone;
  }

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
  }

  public navbarListner(): Observable<string>  {
    return this.navbarToggle.asObservable();
  }

  public toggleTextListener() {
    return this.toggleText.asObservable();
  }
  toggleTextCallback(callback: boolean) {
    this.toggleText.next(callback);
  }

  public callNavbarListner() {
    this.navbarToggle.next(false);
  }
  // public removeCanvas(name) {
  //   let drawables: any = this.async.transform(this.drawables$);
  //   let index = drawables.findIndex((drawable) => drawable.name === name);
  //   drawables.splice(index, 1);
  //   this.drawablesSubject.next(drawables);
  //   this.subscriptions.forEach((subscription: Subscription) => {
  //     subscription.unsubscribe();
  //   });
  //   this.unsubscribe$.next();
  //   this.unsubscribe$.complete();

  // }
  public registerCanvas(name, canvas, container, zIndex = 0) {
    let drawables: any = this.async.transform(this.drawables$);
    canvas.width = name === 'pdf-viewer' ? container.clientWidth : container.element.nativeElement.clientWidth;
    canvas.height = name === 'pdf-viewer' ? container.clientHeight : container.element.nativeElement.clientHeight;
    // canvas.width = container.element.nativeElement.clientWidth;
    // canvas.height = container.element.nativeElement.clientHeight;

    // while (canvas.height == 0) {
    //   this.registerCanvas(name, canvas, container);
    // }
    if (name === 'pdf-viewer') {
      // canvas.width = canvas.width - (canvas.width * .20);

    }
    const context = canvas.getContext('2d');

    context.lineWidth = this.lineWidth;
    context.lineCap = this.lineCap;
    context.strokeStyle = this.strokeStyle;
    context.imageSmoothingQuality = 'high';
    // context.font = this.userSettings.tilefont.split('-')[1];
    const index = drawables.findIndex((c) => c.name === name);
    const drawable = {
      canvas,
      container,
      context,
      textBoxes: [],
      strokes: [],
      name,
    };
    if (index > -1 && name !== 'activity-header') {
      drawables.splice(index, 1);
    } else if (index > -1 && name === 'activity-header') {
      let pad = drawables.find((drawable) => drawable.name === name);
      drawable.textBoxes = pad.textBoxes;
      drawable.strokes = pad.strokes;
      drawables.splice(index, 1);
      this.killEvents(name);

    }
    drawables.push(drawable);
    this.captureEvents(name, zIndex);
    this.drawablesSubject.next(drawables);
  }
  public checkTextBoxes() {
    let textBox = ActivityHeaderService.body.element.nativeElement.nextElementSibling;
    if (textBox !== null) {
      let parent = this.renderer.parentNode(textBox);
      this.renderer.removeChild(parent, textBox);
      this.tool = '';
    }
  }

  public updateCanvas(name, canvas, container, zIndex = 0, draw = true) {
    // Make our in-memory canvas
    const inMemCanvas = document.createElement('canvas');
    const inMemCtx = inMemCanvas.getContext('2d');
    if (canvas.width > 0 && canvas.height > 0) {
      inMemCanvas.width = canvas.width;
      inMemCanvas.height = canvas.height;
      inMemCtx.drawImage(canvas, 0, 0);
    }
    // canvas.width = 1000;
    if (container.element) {
      canvas.width = container.element.nativeElement.clientWidth || 100;
      canvas.height = container.element.nativeElement.clientHeight || 100;
    } else if (container.nativeElement) {
      canvas.width = container.nativeElement.clientWidth || 100;
      canvas.height = container.nativeElement.clientHeight || 100;
    } else {
      canvas.width = container.clientWidth || 100;
      canvas.height = container.clientHeight || 100;

    }
    const context = canvas.getContext('2d');

    context.lineWidth = this.lineWidth;
    context.lineCap = this.lineCap;
    context.strokeStyle = this.strokeStyle;
    if (this.tool === 'eraser') {
      context.globalCompositeOperation = 'destination-out';
    }
    // context.font = this.userSettings.tilefont.split('-')[1];
    if (draw) {
      context.drawImage(inMemCanvas, 0, 0);
    }
  }

  rotateCanvas(canvas, angle) {
    const context = canvas.getContext('2d');
    context.rotate(angle * Math.PI / 180);
  }
  // public toggleColor(color) {
    // let drawables: any = this.async.transform(this.drawables$);
    // this.color = color;
    // switch (color) {
    //   case 'blue':
    //     this.strokeStyle = '#9fbbff';
    //   break;
    //   case 'green':
    //     this.strokeStyle = '#b2ffa8';
    //   break;
    //   case 'pink':
    //     this.strokeStyle = '#ff00ae';
    //   break;
    //   case 'white':
    //     this.strokeStyle = '#fff';
    //     break;
    //   default:
    //     this.strokeStyle = '#000';
    //   break;
    // }

    // drawables.forEach(({ context }) => {
    //   context.strokeStyle = this.strokeStyle;
    // });

    // this.toggleTool('draw', true);
  // }

  public setMarkerColor(color, from?) {
    let drawables: any = this.async.transform(this.drawables$);
    this.color = color;
    this.strokeStyle = this.color;
    drawables.forEach(({ context }) => {
      context.strokeStyle = this.color;
    });

    if (from !== "init") {
      // this.toggleTool('draw', true)
    }
    this.drawablesSubject.next(drawables);
  }
  public killEvents(name, all: boolean = false) {
    if (all) {
      // this.subscriptions.forEach((pack: any) => {
      //   pack.forEach((subscription: Subscription) => {
      //     subscription.unsubscribe();
      //   });
      // });
      for (let key in this.subscriptions) {
        if (this.subscriptions.hasOwnProperty(key) && key !== 'general') {
          this.subscriptions[key].forEach(subscription => {
            subscription.unsubscribe();
          });
        }
      }
    } else {
      this.subscriptions[name].forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
      this.subscriptions[name] = [];
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

}
  public toggleTool(tool, fromColor?) {
    let drawables: any = this.async.transform(this.drawables$);

    this.tool = tool;
    this.toolSubject.next(tool);

    this.store.dispatch(new SetActivitySelectedTool({tool: this.tool}));

    drawables.forEach(({ canvas, context }) => {
      let cursor = '';

      // cursor = this.tool === 'text' ?
      //   'text' :
      //   'default';

      // canvas.style.cursor = 'default';




      if (tool === 'eraser') {
        context.globalCompositeOperation = 'destination-out';
        context.strokeStyle = 'rgba(0,0,0,1)';
        context.lineWidth = this.eraserWidth;
      }

      if (tool === 'draw') {
        context.globalCompositeOperation = this.globalCompositeOperation;
        context.strokeStyle = this.strokeStyle;
        context.lineWidth = this.lineWidth;
      }
    });
    // this.drawablesSubject.next(drawables);
    // if (tool === 'draw' && !fromColor) {
    //   this.setMarkerColor(this.color);
    // }
  }
  public toggleTypingText(toggle: boolean) {
    if (toggle) {
      this.listenerService.callback('show text');

    } else {
      this.listenerService.callback('hide text');

    }
  }

  public clearCanvases(tool) {
    let drawables: any = this.async.transform(this.drawables$);

    this.tool = tool;
    this.clearStrokes();
    drawables = this.clearTextBoxes(drawables);
    for (let drawable of drawables) {
      let { context, canvas, container, textBoxes, strokes } = drawable; // eslint-disable-line
      if (drawable.textBoxes?.length > 0) {
        drawable.textBoxes.forEach((textBox, index, array) => {
          let child = textBox.location.nativeElement;
          let parent = this.renderer.parentNode(child);
          textBox.instance.textBoxValue.nativeElement.remove();
          this.renderer.removeChild(ActivityHeaderService.body, child);
        });
        drawable.textBoxes = [];
      }

      if (drawable.name === 'activity-header') {
        // this.captureEvents(drawable.name, 10);
        this.registerCanvas(drawable.name, drawable.canvas, drawable.container, 10);
      } else {
        // this.captureEvents(drawable.name, 1);
        this.registerCanvas(drawable.name, drawable.canvas, drawable.container, 1);
      }
    }
    this.drawablesSubject.next(drawables);
    this.toggleTool(this.tool, true);
    this.undoQueue = [];
    this.isTrashcanClicked = true;
  }

  private getEventPosition(event, element, draggable = false, type = false) {

    const rect = element.getBoundingClientRect();
    const { x, y, clientX, clientY, } = type ? event.targetTouches[0] : event;

    return {
      x: clientX - rect.left,
      y: clientY - rect.top,
    };
  }

  public captureEvents(drawableName, zIndex) {
    let drawables: any = this.async.transform(this.drawables$);
    let drawable = drawables.find((drawable: any) => drawable.name === drawableName);
    if (!drawable) {
      return;
    }
    let { canvas, context, container, textBoxes, strokes } = drawable;
    let keys = Object.keys(this.subscriptions);
    if (keys.includes(drawableName)) {
      this.killEvents(drawableName);
    }
    this.subscriptions[drawableName] = [];

    // this will capture all mousedown events from the canvas element

    this.subscriptions[drawableName].push(fromEvent(canvas, 'mousedown')
      .pipe(switchMap(e => {
        return fromEvent(canvas, 'mouseup');
      }) )
      .subscribe(result => {
        if (this.tool === 'draw' || this.tool === 'eraser') {
          const start = this.getEventPosition(result, canvas, false, false);
          this.drawOnCanvas(drawable.name, {x: start.x, y: start.y}, {x: start.x, y: start.y});
        }
      })
    );

    this.subscriptions[drawableName].push(fromEvent(canvas, 'mousedown')
      .pipe(
        switchMap((e) => {
          // add a new stroke and keep track of styles
          strokes.push({
            subStrokes: [],
            style: {
              // lineWidth: context.lineWidth,
              lineWidth: this.tool === 'eraser' ? this.eraserWidth : this.lineWidth,
              strokeStyle: context.strokeStyle,
              // operation: context.globalCompositeOperation
              operation: this.tool === 'eraser' ? 'destination-out' : context.globalCompositeOperation
            }
          });
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvas, 'mousemove').pipe(
            // we'll stop (and unsubscribe) once the user releases the mouse
            // this will trigger a 'mouseup' event
            takeUntil(fromEvent(canvas, 'mouseup')),
            // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
            takeUntil(fromEvent(canvas, 'mouseleave')),
            // pairwise lets us get the previous value to draw a line from
            // the previous point to the current point
            pairwise()
         );

        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        if (!res) {
          return;
        }
        if (this.tool === 'draw' || this.tool === 'eraser') {
          // previous and current position with the offset
          const start = this.getEventPosition(res[0], canvas, false, false);
          const end = this.getEventPosition(res[1], canvas, false, false);
          let parentClasses = Array.from(canvas.parentElement.classList);
          if (parentClasses.includes('pad-container')) {
            this.drawOnCanvas(drawable.name, {x: start.x - 3, y: start.y }, {x: end.x - 3, y: end.y });
          } else if (drawable.name.toLowerCase() === 'pdf viewer') {
            this.drawOnCanvas(drawable.name, {x: start.x , y: start.y}, {x: end.x, y: end.y});
          }  else {
            this.drawOnCanvas(drawable.name, {x: start.x, y: start.y}, {x: end.x, y: end.y});
          }
          // this method we'll implement soon to do the a ctual drawing
        }
      }));

    // this will capture all touchdown events from the canvas element
    this.subscriptions[drawableName].push(fromEvent(canvas, 'touchstart')
    .pipe(
      switchMap((e) => {
        // add a new stroke and keep track of styles
        // debugger;
        strokes.push({
          subStrokes: [],
          style: {
            lineWidth: context.lineWidth,
            strokeStyle: context.strokeStyle,
            operation: context.globalCompositeOperation
          }
        });
        // after a mouse down, we'll record all mouse moves
        return fromEvent(canvas, 'touchmove').pipe(
           // we'll stop (and unsubscribe) once the user releases the mouse
           // this will trigger a 'mouseup' event
          //  takeUntil(fromEvent(canvas, 'touchmove')),
           // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
           takeUntil(fromEvent(canvas, 'touchend')),
           // pairwise lets us get the previous value to draw a line from
           // the previous point to the current point
           pairwise()
        );
      })
    )
    .subscribe((res: [TouchEvent, TouchEvent]) => {
      if (this.tool === 'draw' || this.tool === 'eraser') {
        // previous and current position with the offset
        const start = this.getEventPosition(res[0], canvas, false, true);
        const end = this.getEventPosition(res[1], canvas, false, true);

        // this method we'll implement soon to do the actual drawing
        // this.drawOnCanvas(drawable, start, end);
        let parentClasses = Array.from(canvas.parentElement.classList);
        if (parentClasses.includes('pad-container')) {
          this.drawOnCanvas(drawable.name, {x: start.x - 3, y: start.y }, {x: end.x - 3, y: end.y });
        } else {
          this.drawOnCanvas(drawable.name, {x: start.x, y: start.y}, {x: end.x, y: end.y});
        }
      }
    }));

    // Base Text Tool
    const body = document.getElementsByTagName('body')[0];
    if (drawableName !== 'activity-header') {
      this.subscriptions[drawableName].push(fromEvent(body, 'click')
      .pipe(
        map((e: any) => {
          // if (this.isTrashcanClicked) {
          //   return of(null);
          // }
          if (
            (!(e.target.classList.contains('text-tool-enabled')))
          ) {
            return null;
          }

          return e;
        }),
        debounceTime(100),

      )
      .subscribe((e: any) => {
      if (!e) {
        return;
      }

      if (this.tool === "") {
        return;
      }
      // const timeDiff = (Date.now() - this.lastClick);
      // if (timeDiff < 500) {
      //   return;
      // }
      // this.lastClick = Date.now();

      // const textBoxFocusedPredicate = textBoxes.some((textBox: any) => {
      //   return textBox.instance.focused === true;
      // });

      // if (ActivityHeaderService.isMouseOverDeadzone || textBoxFocusedPredicate) {
      //   return;
      // }

      if (
        this.activityName === 'flashcards' ||
        this.activityName === 'lettercards' ||
        this.activityName === 'sentence parts' ||
        this.activityName === 'sentences' ||
        this.activityName === 'passages'
      ) {
        return;
      }

      if (this.tool === 'text' && !this.textboxRef?.instance?.focused) {
        this.listenerService.callback('destroy all text');
        this.listenerService.callback('body');
        if (this.activeActivity !== drawableName) {
          return;
        }
            setTimeout(() => {
              let position: any;
              const rectBody = body.getBoundingClientRect();
              let parentClasses = Array.from(e.target.nextElementSibling.parentElement.classList);
              // const x = e.pageX - (this.navbarListenerService.isSidenavExpand ? 358 : 72); // the width of the

              // const y = parentClasses.includes('pdf-container') ? e.offsetY + 180 : e.pageY;
              let x = e.offsetX;
              let y = e.offsetY;

              if (this.activityName === 'pdf viewer') {
                // if (!this.navbarListenerService.isSidenavExpand) {
                //   x = e.offsetX + 20
                // }
              }
              const texboxMinWidth = 210;
              if ((e.target.width - x) < texboxMinWidth) {
                x = e.target.width - texboxMinWidth;
              }
              TextBoxComponent.activityHeader = this;
              position = { x: x, y: y - (TextBoxComponent.fontSize || this.userSettings.fontSize)};
              const factory = this.resolver.resolveComponentFactory(TextBoxComponent);
              const reference = ActivityHeaderService.activityBody.createComponent(factory);
              this.textboxRef = reference;
              reference.time = Date.now();
              reference.instance.id = textBoxes.length;
              reference.instance.position = position;

              reference.instance.zIndex = 10 ;
              if (drawable.name === this.activityName) {
                let y2 = rectBody.height - y;
                let rect = drawable.container.element.nativeElement.getBoundingClientRect();
                reference.instance.maxWidth = rect.width - x;
                // reference.instance.maxWidth = texboxMinWidth;
                reference.instance.maxHeight = y2;

                reference.instance.parent = this.activityName === 'pdf viewer'  ? null : drawable.container.element.nativeElement;

                if (this.activityName === 'pdf viewer') {

                }

                drawable.textBoxes.push(reference);
                this.subscriptions[drawableName].push(
                  this.togglePadSubject
                    .asObservable()
                    .subscribe((isOpen) => {
                      const opacity = isOpen ? .2 : 1;
                      drawable.textBoxes.forEach((textbox: any) => {
                        textbox.instance.opacity = opacity;
                        textbox.instance.pointerEvents = isOpen ? 'none' : 'auto';
                      });
                    })
                );

                this.subscriptions[drawableName].push(
                  reference
                    .instance
                    .toggleHover
                    .subscribe((isHovering: boolean) => ActivityHeaderService.isMouseOverDeadzone = isHovering)
                );

                this.subscriptions[drawableName].push(this.router.events
                  .subscribe((event: any) => {
                    // You only receive NavigationStart events

                    if (event instanceof NavigationEnd) {
                      setTimeout(() => {
                        drawable.textBoxes.forEach((textBox, index, array) => {
                          let child = textBox.location.nativeElement;
                          let parent = this.renderer.parentNode(child);
                          textBox.instance.textBoxValue.nativeElement.remove();
                          drawable.textBoxes.splice(index, 1);

                          this.renderer.removeChild(ActivityHeaderService.body, child);

                        });
                        // this.tool = '';
                        // this.toggleTool('draw');

                      }, 500);

                    }
                  })
                );

                this.subscriptions[drawableName].push(reference.instance.destroy.subscribe(() => {
                    if (drawable.textBoxes?.length > 0) {
                      drawable.textBoxes.forEach((textBox, index, array) => {
                        if (textBox.instance.textBoxValue) {
                          if (textBox.instance && textBox.instance.textBoxValue.nativeElement.innerText === "") {
                            let child = textBox.location.nativeElement;
                            let parent = this.renderer.parentNode(child);
                            textBox.instance.textBoxValue.nativeElement.remove();

                            this.renderer.removeChild(parent, child);
                            // textBoxes.splice(index, 1);
                          }
                        }
                      });
                    }
                  })
                );
                let index = drawables.findIndex((draw) => draw.name === drawable.name);
                // drawables.forEach((draw) => {
                //   if(draw.name === drawable.name){
                //     draw = drawable;
                //   }
                // })
                drawables[index] = drawable;
                this.drawablesSubject.next(drawables);
              } else {
                let newDrawable = drawables.find((drawable) => drawable.name === this.activityName);
                let rect = newDrawable.container.element.nativeElement.getBoundingClientRect();
                let y2 = rectBody.height - y;
                let height = rectBody.height >= rect.height ? rectBody.height : rect.height;
                reference.instance.maxWidth = rect.width - x;
                reference.instance.maxHeight = y2;
                reference.instance.parent = newDrawable.container.element.nativeElement;


                reference.instance.parent = this.activityName === 'pdf viewer'  ? null : drawable.container.element.nativeElement;

                newDrawable.textBoxes.push(reference);
                this.subscriptions[newDrawable.name].push(
                  this.togglePadSubject
                    .asObservable()
                    .subscribe((isOpen) => {
                      const opacity = isOpen ? .2 : 1;
                      newDrawable.textBoxes.forEach((textbox: any) => {
                        textbox.instance.opacity = opacity;
                      });
                    })
                );

                this.subscriptions[newDrawable.name].push(
                  reference
                    .instance
                    .toggleHover
                    .subscribe((isHovering: boolean) => ActivityHeaderService.isMouseOverDeadzone = isHovering)
                );

                this.subscriptions[newDrawable.name].push(this.router.events
                  .subscribe((event: any) => {
                    // You only receive NavigationStart events

                    if (event instanceof NavigationEnd) {
                      setTimeout(() => {
                        newDrawable.textBoxes.forEach((textBox, index, array) => {
                          let child = textBox.location.nativeElement;
                          let parent = this.renderer.parentNode(child);
                          textBox.instance.textBoxValue.nativeElement.remove();
                          newDrawable.textBoxes.splice(index, 1);

                          this.renderer.removeChild(ActivityHeaderService.body, child);

                        });
                        // this.tool = '';
                        // this.toggleTool('draw');

                      }, 500);

                    }
                  })
                );

                this.subscriptions[newDrawable.name].push(reference.instance.destroy.subscribe(() => {
                    if (newDrawable.textBoxes?.length > 0) {
                      newDrawable.textBoxes.forEach((textBox, index, array) => {
                        if (textBox.instance.textBoxValue) {
                          if (textBox.instance && textBox.instance.textBoxValue.nativeElement.innerText === "") {
                            let child = textBox.location.nativeElement;
                            let parent = this.renderer.parentNode(child);
                            textBox.instance.textBoxValue.nativeElement.remove();

                            this.renderer.removeChild(parent, child);
                            // textBoxes.splice(index, 1);
                          }
                        }
                      });
                    }
                  })
                );
                let index = drawables.findIndex((draw) => draw.name === newDrawable.name);
                // drawables.forEach((draw) => {
                //   if(draw.name === drawable.name){
                //     draw = drawable;
                //   }
                // })
                drawables[index] = newDrawable;
                this.drawablesSubject.next(drawables);


              }
            }, 100);


      }
      }));
    }

    this.scroll.scrolled()
    .subscribe(event => {
      // @ts-ignore
      this.scrollTop = event.elementRef.nativeElement.scrollTop;
    });
    this.subscriptions[drawableName].push(fromEvent(canvas, 'click')
    .pipe(
      map((e: any) => {
        if (
          (!(
            e.target.classList.contains('pad-text-tool-enabled') &&
            (drawable.name === "activity-header" && this.tool === 'text')
          ))
        ) {
          return null;
        }
        return e;
      }),
      debounceTime(100),
    )
    .subscribe((e: any) => {
      if (!e) {
        return;
      }
      // if (!(drawable.name === "activity-header" && this.tool === 'text')) {
      //   return;
      // }

      let parentClasses = Array.from(canvas.parentElement.classList);
      // const timeDiff = (Date.now() - this.lastClick);
      // if (timeDiff < 500) {
      //   return;
      // }

      // this.lastClick = Date.now();

      const textBoxFocusedPredicate = textBoxes.some((textBox: any) => {
        return textBox.instance.focused === true;
      });
      if (textBoxFocusedPredicate) {
        return;
      }
      // let drawables: any = this.async.transform(this.drawables$);
      if (this.tool === 'text') {
        if (textBoxes.every((textbox) => textbox.instance.textBoxValue.nativeElement.innerHTML === '')) {
          this.listenerService.callback('destroy all text');
        }
        setTimeout(() => {
          let position: any;
          TextBoxComponent.activityHeader = this;

          const factory = this.resolver.resolveComponentFactory(TextBoxComponent);
          const reference = container.createComponent(factory);
          this.textboxRef = reference;
          position = this.getPadPosition(e, reference);
          if (this.navbarListenerService.isSidenavExpand) {
            position.x -= 286;
          }
          reference.instance.position = position;
          reference.instance.positions = this.positions;
          reference.instance.zIndex = zIndex ;

          const rootViews = Array.from(container._lContainer);

          let rect = drawable.container.element.nativeElement.getBoundingClientRect();
          reference.instance.maxWidth = (rect.width - position.x) - (.02 * (rect.width - position.x));
          reference.instance.destroy.subscribe((result: any ) => {
            drawable.textBoxes.forEach(element => {
              if (element.instance.textBoxValue.nativeElement.innerHTML === '') {
                element.instance.textBoxValue.nativeElement.remove();
                  let child = element.location.nativeElement;
                  let parent = this.renderer.parentNode(child);
                  this.renderer.removeChild(parent, child);
              }
            });

          });
          drawable.textBoxes.push(reference);

          this.togglePadSubject
            .asObservable()
            .subscribe((isOpen) => {
              const opacity = isOpen ? 1 : 0;
              drawable.textBoxes.forEach((textbox: any) => {
                textbox.instance.opacity = opacity;
                textbox.instance.pointerEvents = isOpen ? 'auto' : 'none';
              });
            });

          let index = drawables.findIndex((draw) => draw.name === drawable.name);
          // drawables.forEach((draw) => {
          //   if(draw.name === drawable.name){
          //     draw = drawable;
          //   }
          // })
          drawables[index] = drawable;
          this.drawablesSubject.next(drawables);
        }, 100);
      }
    }));
  }
  public getPadPosition(e: any, reference: any) {
    let y =  e.clientY + this.scrollTop;
    let changed = reference.instance.fontChanged();
    let font = (changed)
    ? reference.instance.textBoxFontSize
    : (this.userSettings.fontSize || 40);
    let position;
    switch (font) {
      case 24:
        position = { x: e.clientX - 74, y: y - 32};
        break;
      case 32:
        position = { x: e.clientX - 76, y: y - 36};
        break;
      case 40:
        position = { x: e.clientX - 76, y: y - 46};
        break;
      case 48:
        position = { x: e.clientX - 76, y: y - 54};
        break;
      case 64:
        position = { x: e.clientX - 76, y: y - 71};
        break;
    }
    let fonts = [24, 32, 40, 48, 64];
    fonts.forEach((font) => {
      let position;
      switch (font) {
        case 24:
          position = { x: e.clientX - 74, y: y - 32};
          break;
        case 32:
          position = { x: e.clientX - 76, y: y - 36};
          break;
        case 40:
          position = { x: e.clientX - 76, y: y - 46};
          break;
        case 48:
          position = { x: e.clientX - 76, y: y - 54};
          break;
        case 64:
          position = { x: e.clientX - 76, y: y - 71};
          break;
        }
      this.positions.push({
        font: font,
        position: position
      });
    });
    return position;
  }
  public clearTextBoxes(drawables: any[]) {
    // let drawables: any = this.async.transform(this.drawables$);
    if (drawables.length > 0) {

    }
    for (let drawable of drawables) {
      drawable.textBoxes.forEach((textBox, index, array) => {
        let child = textBox.location.nativeElement;
        let parent = this.renderer.parentNode(child);
        textBox.instance.textBoxValue.nativeElement.remove();
        this.renderer.removeChild(ActivityHeaderService.body, child);
      });
      drawable.textBoxes = [];

      if (drawable.name === 'activity-header') {
        // this.captureEvents(drawable.name, 10);
        this.registerCanvas(drawable.name, drawable.canvas, drawable.container, 10);
      } else {
        // this.captureEvents(drawable.name, 1);
        this.registerCanvas(drawable.name, drawable.canvas, drawable.container, 1);


      }
    }
    let pad = drawables.find((drawable) => drawable.name === 'activity-header');
    if (pad?.textBoxes.length > 0) {
      pad.textBoxes.forEach((textBox, index, array) => {
        let child = textBox.location.nativeElement;
        let parent = this.renderer.parentNode(child);
        textBox.instance.textBoxValue.nativeElement.remove();
        this.renderer.removeChild(ActivityHeaderService.body, child);
      });
    }
    this.checkTextBoxes();
    return drawables ? drawables : [];

  }
    public clearStrokes() {
    let drawables: any = this.async.transform(this.drawables$);
    this.killEvents('', true);
    for (let drawable of drawables) {
      // drawable.textBoxes.forEach((textBox, index, array) => {
      //   let child = textBox.location.nativeElement;
      //   let parent = this.renderer.parentNode(child);
      //   textBox.instance.textBoxValue.nativeElement.remove();
      //   this.renderer.removeChild(ActivityHeaderService.body, child);
      // });
      drawable.context.clearRect(0, 0, drawable.canvas.width, drawable.canvas.height);

      let strokesLength = drawable.strokes.length;
      for (let i = 0; i < strokesLength; i++) {
        drawable.strokes.pop();
      }
      this.redrawStrokes(drawable);
    }
    this.drawablesSubject.next(drawables);
  }

  public undo(tool, state, activity) {
    let drawables: any = this.async.transform(this.drawables$);

    this.tool = tool;
    let canvasInstance: any;
    // const last = this.undoQueue.pop();
    let last;
    this.undoQueue.forEach((queue) => {
      queue.strokes.forEach((stroke, index, array) => {
        if (stroke.subStrokes.length === 0) {
          // delete queue.strokes[index]
          queue.strokes.splice(index, 1);
        }
      });
    });
    if (state === 'open') {
      // if(this.undoQueue[this.undoQueue.length - 1].name === 'activity-header')
      for (let i = this.undoQueue.length; i > 0; i--) {
        if (this.undoQueue[i - 1].name === 'activity-header') {
           last = this.undoQueue[i - 1];
           break;
        }
      }
      // && this.undoQueue[i - 1].strokes.every((stroke) => stroke.subStrokes.length > 0
      // drawables.forEach((drawable: any) => {
      //   if (drawable.name === 'activity-header') {
      //     canvasInstance = drawable;
      //   }
      // });
    } else if (state === 'close') {
      // drawables.forEach((drawable: any) => {
      //   let name = drawable.name.split('-').join(' ');
      //   activity = activity.toLowerCase();
      //   if (name === activity) {
      //     canvasInstance = drawable;
      //   }
      // });\
      for (let i = this.undoQueue.length; i > 0; i--) {
        let name = this.undoQueue[i - 1].name;
        if (name === activity.toLowerCase()) {
           last = this.undoQueue[i - 1];
           break;
        }
      }

    }
    // canvasInstance.strokes.filter((stroke) => stroke.subStrokes.length !== 0);

    // canvasInstance.strokes.pop();
    // this.redrawStrokes(canvasInstance);
    // this.drawablesSubject.next(drawables)
    if (last) {
      // last.strokes.pop();
      last.strokes.splice(last.strokes.length - 1, 1);
      // delete last.strokes[last.strokes.length - 1]
      this.redrawStrokes(last);
    }
  }

  private drawOnCanvas(drawableName, start, end) {
    let drawables: any = this.async.transform(this.drawables$);
    let drawable = drawables.find((drawable: any) => drawable.name === drawableName);

    let last;
    for (let i = this.undoQueue.length; i > 0; i--) {
      if (this.undoQueue[i - 1].name === drawable.name) {
        last = this.undoQueue[i - 1];
        break;
     }
    }
    let strokesLength = drawable.strokes.length;

    if (!last && strokesLength > 1) {
      for (let i = 0; i < strokesLength - 1; i++) {
        drawable.strokes.pop();
      }
    }
    drawable = !last ? drawable : last;
    const stroke = !last
    ? drawable.strokes[drawable.strokes.length - 1]
    : last?.strokes[last.strokes.length - 1];
    if (stroke) {

      if (stroke.subStrokes?.length === 0) {
        this.undoQueue.push(drawable);
      }
      stroke.subStrokes?.push({ start, end });
      this.redrawStrokes(drawable);
    } else {
      let strokes: any[] = [];
      strokes.push({
        subStrokes: [],
        // style: {
        //   lineWidth: drawable.context.lineWidth,
        //   strokeStyle: drawable.context.strokeStyle,
        //   operation: drawable.context.globalCompositeOperation
        // }
        style: {
          lineWidth: this.lineWidth,
          strokeStyle: this.strokeStyle,
          operation: drawable.context.globalCompositeOperation
        }
      });
      drawable.strokes = strokes;
      this.redrawStrokes(drawable);

    }
  }

  public redrawStrokes({ canvas, context, strokes }) {
    if (!context) { return; }

    context.clearRect(0, 0, canvas.width, canvas.height);

    strokes.forEach(({ subStrokes, style }) => {
      context.lineWidth = style.lineWidth;
      context.strokeStyle = style.strokeStyle;
      context.globalCompositeOperation = style.operation;

      subStrokes.forEach(({ start, end }) => {
        context.beginPath();
        context.moveTo(start.x, start.y);
        context.lineTo(end.x, end.y);
        context.stroke();
      });
    });
    this.toggleTool(this.tool, false);

  }

  public setActiveInitTool(tool: 'draw' | 'text') {
    this.activeInitTool = tool;
  }

  ngOnDestroy(): void {
    // this.subscriptions.forEach((subscription: Subscription) => {
    //   subscription.unsubscribe();
    // });
    // this.unsubscribe$.next();
    // this.unsubscribe$.complete();
    this.killEvents('', true);
  }
}
