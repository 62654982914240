import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-words-dialog',
  templateUrl: './view-words-dialog.component.html',
  styleUrls: ['./view-words-dialog.component.scss']
})
export class ViewWordsDialogComponent implements OnInit {
  viewWordsArray: any = [];
  wordCount: number;

  isLoading =  false;

  disabled = false;
  compact = false;
  invertX = false;
  invertY = false;
  shown: 'native' | 'hover' | 'always' = 'native';
  constructor(public dialogRef: MatDialogRef<ViewWordsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.isLoading = false;
    for (let i = 0; i < 8; i++) {
      this.viewWordsArray.push(this.data.words.preview[i]);
    }
  }

  loadMore() {
    if (this.viewWordsArray.length <= this.data.words.preview.length) {
      this.isLoading = true;
      this.wordCount = this.viewWordsArray.length + 8;
      for (let i = this.viewWordsArray.length; i < this.wordCount; i++) {
        if ((this.data.words.preview[i] !== undefined) && (this.data.words.preview[i].wordid)) {
          setTimeout(() => {
          this.viewWordsArray.push(this.data.words.preview[i]);
            this.isLoading = false;        
          }, 1000);
        }
      }
    }
  }

  setShown() {
    if (this.shown === 'native') {
      this.shown = 'hover';
    } else if (this.shown === 'hover') {
      this.shown = 'always';
    } else {
      this.shown = 'native';
    }
  }
}
