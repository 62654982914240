// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      :host {
        display: block;
        overflow: hidden;
      }
    `, "",{"version":3,"sources":["webpack://./src/core/components/course-workbook-edit/course-workbook-edit-words.component.ts"],"names":[],"mappings":";MACM;QACE,cAAc;QACd,gBAAgB;MAClB","sourcesContent":["\n      :host {\n        display: block;\n        overflow: hidden;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
