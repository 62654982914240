import * as SetCourseActions from '../set-course/set-course.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { tap, take, map, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, forkJoin } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CourseService } from '../../services/courses.service';
import * as actions from './courses.actions';
import { getAllNonAndSubscriptionCourses, isSetCourseLoaded, selectGetAllSubscription } from '..';
import { SubscriptionService } from '../../services/subscription.service';
import { GetAllSubscribedCoursesSuccess } from '../subscriptions/subscriptions.actions';
import { GetMyLibraryWorkbooks } from '../workbook/workbook.actions';

@Injectable()

export class CoursesEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private courseService: CourseService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {}
    getCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseActionTypes.GetCourse),
            map((action: actions.GetCourse) => action.payload),
            // tap(courseId => {
            //     this.store$.dispatch(new CourseWorkbookActions.GetAllCourseWorkbooks(courseId));
            // }),
            switchMap((payload: any) => {
                return this.courseService.get(payload).pipe(
                    map((res: string) => new actions.GetCourseSuccess(res)),
                    catchError((error) => of(new actions.GetCourseFail(error.message)))
                );
            })
        );
    });

    getAllCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseActionTypes.GetAllCourse),
            // map((action: actions.GetAllCourse) => action.payload),
            switchMap((action: any) => {
                return of(action).pipe(
                    withLatestFrom(
                        this.store$.select(isSetCourseLoaded)
                    ),
                    switchMap(([action, isLoaded]) => {
                        return this.courseService.getAll().pipe()
                            .pipe(
                                map((res: any) => {
                                    const result = res.sort((a, b) => {
                                        return a.order - b.order;
                                    });
                                    return result;
                                }),
                                // tap((res: any) => {
                                //     if (!isLoaded) {
                                //         this.store$.dispatch(
                                //             new SetCourseActions.SetCurriculumCourse({
                                //                 course: res[0],
                                //                 redirect: false
                                //             }),
                                //         );
                                //     }
                                // }),
                                switchMap((res: any) => {
                                    return [new actions.GetAllCourseSuccess(res)];
                                }),
                                catchError((error) => of(new actions.GetAllCourseFail(error.message)))
                            );
                    })

                );

            }),
            // ///
            // switchMap((payload: any) => {
            //     return this.courseService.getAll().pipe(
            //         map((res: any) => {
            //             const result = res.sort((a, b) => {
            //                 return a.order - b.order;
            //             });
            //             return result;
            //         }),
            //         switchMap((res: any) => {
            //             // const currentCourseId = localStorage.currentCourseId;
            //             // let currentCourse = res.find(item => {
            //             //     return item._id === currentCourseId;
            //             // });
            //             // if (!currentCourse) {
            //             //     currentCourse = res[0];
            //             // }
            //             let currentCourse = res[0];
            //             return [/*new actions.ClearCourses(),*/
            //                 // new SetCourseActions.SetCurriculumCourse({
            //                 //     course: currentCourse,
            //                 //     redirect: false
            //                 // }),
            //                 new actions.GetAllCourseSuccess(res)];
            //         }),
            //         withLatestFrom(
            //             this.store$.select(selectGetAllSubscription)
            //         ),

            //         catchError((error) => of(new actions.GetAllCourseFail(error.message)))
            //     );
            // })
        );
    });

    createCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseActionTypes.CreateCourse),
            map((action: actions.CreateCourse) => action.payload),
            switchMap((payload: any) => {
                return this.courseService.create(payload).pipe(
                    map((res) => new actions.CreateCourseSuccess(res)),
                    catchError((error) => of(new actions.CreateCourseFail(error.message)))
                );
            })
        );
    });

    createCourseSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseActionTypes.CreateCourseSuccess),
            map((action: actions.CreateCourseSuccess) => action.payload),
            tap((payload: any) => this.store$.dispatch(new SetCourseActions.SetCurriculumCourse({
                course: payload.data,
                redirect: true
              })))
        );
    }, {dispatch: false});

    // createCourseFail$ = createEffect(() => {
    // })

    updateCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseActionTypes.UpdateCourse),
            map((action: actions.UpdateCourse) => action.payload),
            switchMap((payload: any) => {
                return this.courseService.update(payload).pipe(
                    map((res: any) => {
                        return new actions.UpdateCourseSuccess(payload);
                    }),
                    catchError((error) => of(new actions.UpdateCourseFail(error.message)))
                );
            })
        );
    });

    updateCourseSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseActionTypes.UpdateCourseSuccess),
            map((action: actions.UpdateCourseSuccess) => action.payload),
            tap((payload: any) => {
                this.store$.dispatch(new SetCourseActions.SetCurriculumCourse({
                    course: payload,
                    redirect: true
                  }));
            })
        );
    }, {dispatch: false});

    deleteCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseActionTypes.DeleteCourse),
            map((action: actions.DeleteCourse) => action.payload),
            switchMap((payload: any) => {
                return this.courseService.delete(payload).pipe(
                    map(() => new actions.DeleteCourseSuccess(payload)),
                    catchError((error) => of(new actions.DeleteCourseFail(error.message)))
                );
            })
        );
    });

    deleteCourseSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseActionTypes.DeleteCourseSuccess),
            map((action: actions.DeleteCourseSuccess) => action.payload),
            switchMap((payload: any) => {
                return this.store$.select(getAllNonAndSubscriptionCourses).pipe(
                    map(allCourses => {
                        if (allCourses && allCourses.length) {
                            this.store$.dispatch(new SetCourseActions.SetCurriculumCourse({
                                course: allCourses[0],
                                redirect: true
                              }));
                        }
                    }),
                    take(1)
                );
            })
        );
    }, {dispatch: false});


    copyCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseActionTypes.CopyCourse),
            map((action: actions.CopyCourse) => action.payload),
            switchMap((payload: any) => {
                return this.courseService.copy(payload).pipe(
                    switchMap((result: any) => {
                        return [
                          new actions.CopyCourseSuccess(result.data.course),
                          new GetMyLibraryWorkbooks('')
                        ];
                    }),
                    catchError((error) => of(new actions.CopyCourseFail(error.message)))
                );
            })
        );
    });

    copyCourseSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CourseActionTypes.CopyCourseSuccess),
            map((action: actions.CopyCourseSuccess) => action.payload),
            tap((payload: any) => this.store$.dispatch(new SetCourseActions.SetCurriculumCourse({
                course: payload,
                redirect: true
              })))
        );
    }, {dispatch: false});

}
