import { Store } from '@ngrx/store';
import { ChangeDetectorRef, Component, ViewChild, OnChanges, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {WordsService} from '../../services/words.service';
import {WorkbooksService} from '../../services/workbooks.service';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import { Observable, Subject, combineLatest, Subscription, BehaviorSubject } from 'rxjs';
import { ListenerService } from '../../services/listener.service';
import { Swappable, Sortable, Plugins } from '@shopify/draggable';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { DataService } from '../../services/data.service';
import { getCourseWorkbook, getWorkbook, selectGetPostResponseByWorkbookId, selectGetWorkbookActivity } from '../../store';
import { PostWords } from '../../store/words/words.actions';
import { UpdateLessonStep, UpdateLessonStepSuccess } from '../../store/edit-lesson/edit-lesson.actions';
import {AsyncPipe} from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
import { WordsUpdatingOptionEnum } from './course-workbook-edit.component';
import { GetCourseWorkbook } from '../../store/course-workbook/course-workbook.actions';

@Component({
  selector: 'app-course-workbook-edit-words',
  templateUrl: './html/course-workbook-edit-words.html',
  styles: [
    `
      :host {
        display: block;
        overflow: hidden;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CourseWorkbookEditWordsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild('wordTable', { static: false }) reorderTable: ElementRef;
  @ViewChild('topWordsTableContainer') topWordsTableContainer: ElementRef;
  constructor(private wordsService: WordsService,
              private workbooksService: WorkbooksService,
              private r2: Renderer2,
              private dataService: DataService,
              private listenerService: ListenerService,
              private store: Store,
              private async: AsyncPipe,
              private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.navigatePath = localStorage.getItem('navigatePath');
    if (this.navigatePath.includes('edit-lesson-plan')) {
      this.moreWordsText = 'ADD/REMOVE WORDS';
    } else if (!this.navigatePath.includes('edit-lesson-plan')) {
      this.moreWordsText = 'ADD/REMOVE WORDS';
    }
  }
  @ViewChild('moreListWords', { static: false }) listWordsTable: ElementRef;

  @Input() words: any[] = [];
  @Input() workbookId: string;
  @Input() lessonPlanStep: any;
  @Input() displayMoreWordsText = false;
  @Input() changeWorkbookObservable: Observable<any>;
  @Input() shuffleWordsObservable: Observable<any>;
  @Input() numWordsObservable: Observable<any>;
  @Input() changeWorkbookActivityObservable: Observable<any>;
  @Output() updateLessonPlanStepEvent: EventEmitter<any> = new EventEmitter<any>();


  public pageWords: any[] = [];
  public moreWords: any[] = [];
  public moreWordsRef: any[] = [];
  public courseWorkbook: any = {};

  public pageWordsSubject: any = new BehaviorSubject<any>(this.pageWords);
  public moreWordsSubject: any = new BehaviorSubject<any>(this.moreWords);
  public courseWorkbookSubject: any = new BehaviorSubject<any>(this.courseWorkbook);

  public pageWords$: any = this.pageWordsSubject.asObservable();
  public moreWords$: any = this.moreWordsSubject.asObservable();
  public courseWorkbook$: any = this.courseWorkbookSubject.asObservable();
  filter: any;
  pageSize = 20;
  pageIndex = 0;
  inDropzone = false;

  currentPage = 0;
  isLoading = false;
  moreWordsText = '';
  wordBookText = '';
  navigatePath: any;
  public plan: string;
  hasError = false;
  slicedWords: any[] = [];
  prevSlicedWords: any[] = [];

  showMoreWords = false;
  container: any;
  subscriptions = new Subscription();
  public draggingIndex: any;
  public swappingIndex: any;
  public clientY: number;
  public canDrop = false;
  public canAdd = false;
  public path: string;
  private unsubscribe$: Subject<void> = new Subject();
  wordsLoaded = false;
  getWordsTry = 0;
  wordsOrder: null | 'word' | 'exactWordsOrder' = null;
  responseWords: any;
  workbookActivity: any;
  wordsUpdatingOptionEnum = WordsUpdatingOptionEnum;
  courseWorkbookId: string;
  // courseWorkbook: any;
  @Input() setNumWords(numWords: number) {
}

  ngOnInit(): void {
    this.wordsLoaded = false;
    this.getWordsTry = 0;
    this.subscriptions.add(
      this.dataService._variable_images.subscribe((path: any) => {
        this.path = path;
      })
    );
    this.subscriptions.add(
      this.store.select(getCourseWorkbook, {id: this.lessonPlanStep.courseWorkbookId})
        .pipe(filter(courseWorkbook => !!courseWorkbook))
        .subscribe(courseWorkbook => {
          this.courseWorkbook = courseWorkbook;
          // @ts-ignore
          this.workbookId = courseWorkbook._id;
          // @ts-ignore
          this.filter = courseWorkbook.workbook.filters;
          this.courseWorkbookSubject.next(courseWorkbook);
          this.wordsLoaded = true;
          this.store.dispatch(new PostWords({
            id: this.workbookId,
            filter: this.filter
          }));

        })
    );

    this.subscriptions.add(
      this.moreWords$
        .pipe(map((words: any) => {
          if (this.courseWorkbook.workbook.filters.exactWords?.length) {
            this.wordsOrder = 'exactWordsOrder';
            words.forEach(word => {
              word.exactWordsOrder = this.courseWorkbook.workbook.filters.exactWords.indexOf(word.word);
            });
          }
          return words;
        }))
        .subscribe((words) => {
        this.lessonPlanStep = JSON.parse(JSON.stringify(this.lessonPlanStep));
        this.slicedWords = this.lessonPlanStep.words.slice(0, this.lessonPlanStep.numWords);

        setTimeout(() => {
                this.makeWordTable();

      }, 100);

        this.isLoading = false;

    }));
    if (!this.changeWorkbookObservable) {
      return;
    }

    this.subscriptions.add(this.changeWorkbookObservable
      .subscribe(courseWorkbook => {
        if (!courseWorkbook) { return; }
        if (courseWorkbook.length) {
          courseWorkbook = courseWorkbook[0];
        }
        this.workbookId = courseWorkbook._id;
        this.filter = courseWorkbook.workbook.filters;
        this.courseWorkbookSubject.next(courseWorkbook);
        this.wordsLoaded = true;

        if (this.moreWords.length >= 0) {
          // this.getMoreWords();
        }
        this.changeDetectorRef.detectChanges();

      }));

    this.listenerService.getPlan().subscribe((plan) => {
      this.plan = plan;
    });
    // this.subscriptions.add(
    //   combineLatest([
    //     this.store.select(getWorkbook, {id: this.workbookId}),
    //     this.store.select(selectGetPostResponseByWorkbookId, {id: this.workbookId})
    //   ]).pipe(takeUntil(this.unsubscribe$))
    //   .subscribe(([workbook, postWords]) => {
    //     let courseWorkbook: any = this.async.transform(this.courseWorkbook$)
    //     if (!workbook || !postWords) {
    //       this.filter = null;

    //       if (this.getWordsTry === 1) { // it means the workbook has no words.
    //         this.wordsLoaded = true;
    //         return;
    //       }
    //       if (workbook && !postWords) {
    //         this.store.dispatch(new PostWords({
    //           id: this.workbookId,
    //           filter: workbook.filters
    //         }));
    //         this.getWordsTry = 1;
    //       }

    //       return;
    //     }

    //     this.filter = workbook.filters;

    //     this.wordsLoaded = true;
    //     this.changeDetectorRef.detectChanges();
    //   })

    // );



    if (!this.shuffleWordsObservable) {
      return;
    }

    this.subscriptions.add(
      this.shuffleWordsObservable
        .subscribe(words => {
          this.slicedWords = words;
          // words.forEach(shuffledWord => {
          //   const word = this.lessonPlanStep.words.find(fullWord => fullWord.wordid === shuffledWord.wordid);
          //   if (word) {
          //     this.slicedWords.push(word);
          //   }
          // });

        this.prevSlicedWords = [];

        // this.updateLessonPlanStep();

          this.makeWordTable();
        })
    );

    if (this.lessonPlanStep) {
      this.store.dispatch(new PostWords({
        id: this.lessonPlanStep.workbookId,
        filter: this.courseWorkbook.workbook.filters
      }));

    }

  }
   ngOnChanges(changes: any) {
    if (changes.setNumWords && this.reorderTable) {
      this.lessonPlanStep = {...{}, ...this.lessonPlanStep};
      this.lessonPlanStep.numWords = changes.setNumWords.currentValue;
      // this.buildMoreWords();
      if (this.words.length > 0) {
        this.slicedWords = this.lessonPlanStep.words.slice(0, this.lessonPlanStep.numWords);
      }

      setTimeout(() => {
        this.updateLessonPlanStep();
        this.makeWordTable();
      }, 100);
    }
    // if (changes.lessonPlanStep && changes.lessonPlanStep.currentValue && changes.lessonPlanStep.previousValue) {
    //   if (JSON.parse(JSON.stringify(changes.lessonPlanStep.currentValue.words)) !== JSON.parse(JSON.stringify(changes.lessonPlanStep.previousValue.words))) {
    //     this.slicedWords = this.lessonPlanStep.words;
    //     this.makeWordTable();
    //   }
    // }

    if (changes.words && changes.words.currentValue && changes.words.previousValue && JSON.stringify(changes.words.currentValue) !== JSON.stringify(changes.words.previousValue)) {
      this.slicedWords = changes.words.currentValue;
      this.makeWordTable();
    }

    if (changes.lessonPlanStep) {
      const currentValue = changes.lessonPlanStep.currentValue?.wordUpdating;
      const previousValue = changes.lessonPlanStep.previousValue?.wordUpdating;
      if (currentValue !== previousValue && currentValue === WordsUpdatingOptionEnum.UseWords) {
        this.closeMoreWords();
      }
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit() {
    if (!this.lessonPlanStep) {
      this.store.select(selectGetWorkbookActivity)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbookActivity => {
        this.workbookActivity = workbookActivity;
        // @ts-ignore
        this.slicedWords = workbookActivity.words;
        this.makeWordTable();
      });
    }
  }
  makeWordTable() {
    let newIndex = -1;
    let oldIndex = -1;
    // grab table container
    // for each workbook of workbooks
    // if (this.prevSlicedWords.length > 0) {

    //   if (this.prevSlicedWords.length === this.slicedWords.length) {
    //     let test = this.slicedWords.every((word, index, array) => (word !== null && word.word === this.prevSlicedWords[index].word));
    //     if (test) { return; }
    //   } else if (this.prevSlicedWords.length >= this.slicedWords.length) {
    //     this.prevSlicedWords = [...this.slicedWords];
    //     return this.r2.removeChild(this.reorderTable.nativeElement.lastChild, this.reorderTable.nativeElement.lastChild.lastChild, true);

    //   } else if (this.prevSlicedWords.length <= this.slicedWords.length) {
    //     let prevId = this.prevSlicedWords.map((word) => word.wordid);
    //     let newIds = this.slicedWords.map((word) => word.wordid).filter((id: any) => !prevId.includes(id));
    //     this.slicedWords.forEach((word) => {
    //       if (!prevId.includes(word.wordid)) {
    //         let div = this.r2.createElement('td');
    //         this.r2.addClass(div, 'dropzone');

    //             // create child div with material card class
    //         this.r2.setStyle(div, 'display', 'inline-block');
    //         let matButton = this.r2.createElement('button');
    //         let span = this.r2.createElement('span');
    //         let icon = this.r2.createElement('img');
    //         this.r2.addClass(span, 'mat-button-wrapper');
    //         this.r2.addClass(matButton, 'example-box');
    //         this.r2.addClass(matButton, 'words_tile_box');
    //         this.r2.addClass(matButton, 'mat-stroked-button');
    //         this.r2.setStyle(matButton, 'margin-right', '8px');
    //         this.r2.setStyle(matButton, 'margin-bottom', '8px');
    //         this.r2.setProperty(matButton, 'id', this.slicedWords.length - 1);

    //         let text = this.r2.createText( word.word);
    //         this.r2.appendChild(span, text);
    //         this.r2.setAttribute(icon, 'src', this.path + 'move.png');
    //         this.r2.setStyle(icon, 'position', 'absolute');
    //         this.r2.setStyle(icon, 'left', '20px');
    //         this.r2.setStyle(icon, 'bottom', '15px');

    //         this.r2.appendChild(matButton, span);
    //         this.r2.appendChild(matButton, icon);
    //         this.r2.appendChild(div, matButton);
    //         this.r2.appendChild(this.reorderTable.nativeElement.lastChild, div);
    //         }
    //     });
    //     this.prevSlicedWords = [...this.slicedWords];
    //     return;
    //   }

    // } else {
      if (this.container) {
        this.r2.removeChild(this.reorderTable.nativeElement, this.container);
      }

      let tr = this.r2.createElement('tr');
      this.slicedWords.forEach((word: any, index: number, array: any) => {
        if (!word?.displayWord && !word?.word) {
          return;
        }
            // create parent div
        let div = this.r2.createElement('td');
        this.r2.addClass(div, 'dropzone');
        this.r2.addClass(div, 'drag_box');
            // create child div with material card class
        this.r2.setStyle(div, 'display', 'inline-block');
        let matButton = this.r2.createElement('button');
        let span = this.r2.createElement('span');
        let icon = this.r2.createElement('img');
        this.r2.addClass(span, 'mat-button-wrapper');
        this.r2.addClass(matButton, 'example-box');
        this.r2.addClass(matButton, 'words_tile_box');
        this.r2.addClass(matButton, 'mat-stroked-button');
        this.r2.setStyle(matButton, 'position', 'relative');
        this.r2.setStyle(matButton, 'border-radius', '4px');
        this.r2.setStyle(matButton, 'margin-right', '8px');
        this.r2.setStyle(matButton, 'margin-bottom', '8px');
        this.r2.setProperty(matButton, 'id', index);

        let text = this.r2.createText(word.displayWord || word?.word);
        this.r2.appendChild(span, text);
        this.r2.setAttribute(icon, 'src', this.path + 'move.png');
        this.r2.setStyle(icon, 'position', 'absolute');
        // this.r2.setStyle(icon, 'left', '20px');
        // this.r2.setStyle(icon, 'bottom', '15px');

        this.r2.setStyle(icon, 'left', '8px');
        this.r2.setStyle(icon, 'bottom', '16px');

        this.r2.appendChild(matButton, span);
        this.r2.appendChild(matButton, icon);
        this.r2.appendChild(div, matButton);
        this.r2.appendChild(tr, div);

      });
      this.container = tr;
      this.prevSlicedWords = [...this.slicedWords];

      this.r2.appendChild(this.reorderTable.nativeElement, tr);
          // add Draggablejs swappable object to child element

      // const swappable = new Swappable(tr, {
      //   draggable: '.words_tile_box',
      //   dropzone: '.dropzone',
      //   mirror: {
      //     appendTo: this.reorderTable.nativeElement,
      //     constrainDimensions: true
      //   },
      //   plugins: [Plugins.ResizeMirror]
      // });

      const sortAble = new Sortable(tr, {
        draggable: '.drag_box',
        // @ts-ignore
        dropzone: '.dropzone',
        swapAnimation: { duration : 500, easingFunction : "ease-in-out", horizontal : true },
        plugins : [ Plugins.SwapAnimation ]
      });

      sortAble.on('drag:stop', (event: any) => {
        if (!event.data.source) { return; }

        let tileIndex = parseInt(event.data.source.children[0].id, 10);
        if (this.inDropzone) {
          this.slicedWords = this.slicedWords.filter((word: any, index: number) => index !== tileIndex && word);
          this.lessonPlanStep = {...{}, ...this.lessonPlanStep};
          this.lessonPlanStep.numWords -= 1;
          newIndex = -1;
          oldIndex = -1;
          this.updateLessonPlanStep();
          this.makeWordTable();
        } else {
          let updatedArray = [];
          if (oldIndex > newIndex) { // dragging to left side
           updatedArray = Array.from(event.data.sourceContainer.children).filter((_, i) => i !== (tileIndex + 1) && i !== (event.data.sourceContainer.children.length - 1));
          } else { // dragging to right side
            updatedArray = Array.from(event.data.sourceContainer.children).filter((_, i) => i !== tileIndex && i !== (event.data.sourceContainer.children.length - 1));
          }

          const currentWordOrder = updatedArray
          .map((container: any, i) => {
            const child: any = Array.from(container.children)[0];
            return child.id;
          })
          .filter(index => index !== "").map((value: string) => parseInt(value, 10));

          let swappedWords = [];
          currentWordOrder.forEach(index => {
            swappedWords.push(this.slicedWords[index]);
          });
          this.slicedWords = swappedWords;
          newIndex = -1;
          oldIndex = -1;
          this.updateLessonPlanStep();
          this.makeWordTable();
        }
      });

      sortAble.on('sortable:sorted', (evt) => {
        if (this.inDropzone) { return; }
        // @ts-ignore
        newIndex = evt.data.newIndex;
        // @ts-ignore
        oldIndex = evt.data.oldIndex;
      });

      // this.containers.push({buttonTile: MatButton, tr: tr})

      // swappable.on('mirror:move', (event: any) => {
      //   // @ts-ignore
      //   let stableDiv = this.listWordsTable.element.nativeElement.getBoundingClientRect();
      //   let movingDiv = event.data.sensorEvent.data;
      //   let mirror = event.data.source.getBoundingClientRect();

      //   let verticalPredicate = movingDiv.clientY + mirror.height >= stableDiv.y
      //     && movingDiv.clientY <= stableDiv.y + stableDiv.height;

      //   if (verticalPredicate) {
      //       this.canDrop = true;
      //     } else {
      //       this.canDrop = false;
      //     }
      //   this.clientY = event.data.sensorEvent.data.clientY;
      // });
    //   swappable.on('drag:stop', (event: any) => {
    //     if (!event.data.source) { return; }

    //     let tileIndex = parseInt(event.data.source.id, 10);
    //     if (this.inDropzone) {
    //       this.slicedWords = this.slicedWords.filter((word: any, index: number) => index !== tileIndex && word);
    //       this.lessonPlanStep = {...{}, ...this.lessonPlanStep};
    //       this.lessonPlanStep.numWords -= 1;
    //       this.updateLessonPlanStep();
    //       this.makeWordTable();
    //     }
    //   });
    // swappable.on('swappable:stop', (event: any) => {
    //   if (this.inDropzone) { return; }
    //   const currentWordOrder = Array.from(event.data.dragEvent.data.sourceContainer.children)
    //     .map((container: any) => {
    //       const child: any = Array.from(container.children)[0];
    //       return child.id;
    //     })
    //     .filter(index => index !== "").map((value: string) => parseInt(value, 10));

    //   let swappedWords = [];
    //   currentWordOrder.forEach(index => {
    //     swappedWords.push(this.slicedWords[index]);
    //   });
    //   this.slicedWords = swappedWords;
    //   this.updateLessonPlanStep();
    //   this.makeWordTable();
    // });


      // swappable.on('swappable:start', (event: any) => {

      //   this.draggingIndex = event.data.dragEvent.data.originalSource;

      // });
      // swappable.on('swappable:swapped', (event: any) => {
      //   // this.swappingIndex = parseInt(event.data.swappedElement.id, 10)
      //   this.swappingIndex = event.data.swappedElement;

      // });
      // swappable.on('swappable:stop', (event: any) => {
      //   const swappingIndex = parseInt(this.swappingIndex.id, 10);
      //   const currentIndex = parseInt(this.draggingIndex.id, 10);
      //   this.slicedWords = JSON.parse(JSON.stringify(this.slicedWords));
      //   const temp = this.slicedWords[currentIndex];
      //   this.slicedWords[currentIndex] = this.slicedWords[swappingIndex];
      //   this.slicedWords[swappingIndex] = temp;
      //   // @ts-ignore
      //   // [this.workbooks[currentIndex], this.workbooks[swappingIndex]] = [this.workbooks[swappingIndex], this.workbooks[currentIndex]]
      //   this.r2.removeAttribute(this.swappingIndex, 'id');
      //   this.r2.removeAttribute(this.draggingIndex, 'id');
      //   this.r2.setAttribute(this.draggingIndex, 'id', `${swappingIndex}`);
      //   this.r2.setAttribute(this.swappingIndex, 'id', `${currentIndex}`);

      //   this.updateLessonPlanStep();

      // });

  }

  getMoreWords() {
    this.showMoreWords = true;
    this.store.dispatch(new PostWords({
      id: this.workbookId,
      filter: this.filter
    }));
    setTimeout(() => {
      this.buildMoreWords();
    }, 500);
  }
  clearAllWords() {
    this.lessonPlanStep.numWords = 0;
    this.slicedWords = [];
    this.makeWordTable();
    this.updateLessonPlanStep();
  }
  buildMoreWords() {
    this.hasError = false;
    this.isLoading = true;
    this.workbookId = this.lessonPlanStep.workbookId;
    // this.wordsService
    //   .post(this.filter)
    //   .pipe(
    //     take(1)
    //   )
    this.subscriptions.add(
      this.store.select(selectGetPostResponseByWorkbookId, {id: this.workbookId})
      .pipe(take(1))
      .subscribe(
        (words: any[]) => {
          if (!words) {
            this.moreWordsSubject.next([]);
            if (this.courseWorkbook.fullWords.length > 0) {
              this.store.dispatch(new PostWords({
                id: this.workbookId,
                filter: this.filter
              }));
              // this.buildMoreWords();
            }
            return;
          }
          if (words.length === 0) {
            this.moreWordsText = 'No Words Found!';
          } else if (this.plan === 'Demo') {
            this.moreWordsText = 'Upgrade your account to use more words in this activity.';
          }
          this.responseWords = JSON.parse(JSON.stringify(words));
          this.moreWordsSubject.next(JSON.parse(JSON.stringify(words)));
          const firstWordIndex = this.currentPage * this.pageSize;
          const lastWordIndex = firstWordIndex + this.pageSize;
          this.pageWordsSubject.next(words.slice(firstWordIndex, lastWordIndex));
            // let test = words
          // .slice(0, this.lessonPlanStep.numWords)
          // .every((word1) => {
          //   this.lessonPlanStep.words
          //   .slice(0, this.lessonPlanStep.numWords).find((word2) => word1.wordid === word2.wordid )
          // })
            //   this.slicedWords = this.moreWords.slice(0, this.lessonPlanStep.numWords);

        // },
        // () => {
        //   this.isLoading = false;
        //   this.hasError = true;
        }));
      let courseWorkbook: any = this.async.transform(this.courseWorkbook$);
        if (Object.keys(courseWorkbook).length === 0) {
          this.store.dispatch(new GetCourseWorkbook(this.lessonPlanStep.courseWorkbookId));
          setTimeout(() => {
            this.buildMoreWords();
          }, 300);
        }
        // this.wordsLoaded = true;

    // );
  }

  changePage(pageEv: PageEvent) {
    // this.currentPage = pageEv.pageIndex;
    // this.setPageWords();
    // this.moreWords$
    // .subscribe((result) => {
    //   let words = JSON.parse(JSON.stringify(result));
    //   if (this.wordsOrder === 'word') {
    //     words.sort((a, b) => {
    //       return a.word.toLowerCase().localeCompare(b.word.toLowerCase(), 'en', { sensitivity: 'base' })
    //     })
    //   }
    //   const firstWordIndex = this.currentPage * this.pageSize;
    //   const lastWordIndex = firstWordIndex + this.pageSize;
    //   this.pageWordsSubject.next(words.slice(firstWordIndex, lastWordIndex));
    // });
    this.pageIndex = pageEv.pageIndex;
    this.pageSize = pageEv.pageSize;
  }

   setPageWords() {

    const firstWordIndex = this.currentPage * this.pageSize;
    const lastWordIndex = firstWordIndex + this.pageSize;
    this.pageWordsSubject =  new BehaviorSubject<any>(this.moreWords.slice(firstWordIndex, lastWordIndex));
    this.pageWords$ = this.pageWordsSubject.asObservable();
  }

  range() {
    const moreWords: any[] = this.async.transform(this.moreWords$);
    return [...Array(Math.ceil(moreWords.length / this.pageSize)).keys()];
  }

  updateLessonPlanStep() {
    this.lessonPlanStep = {...{}, ...this.lessonPlanStep};
    this.lessonPlanStep.words = []; // this is showing up as read only, so recreating it so that it can be written to
    this.lessonPlanStep.words = this.slicedWords;
    this.updateLessonPlanStepEvent.emit(this.lessonPlanStep);
  }

  reorderWords($event) {
    if ($event.container.id === $event.previousContainer.id) {
      moveItemInArray($event.container.data, $event.previousIndex, $event.currentIndex);
    } else {
      transferArrayItem($event.previousContainer.data, $event.container.data, $event.previousIndex, $event.currentIndex);

      if (this.lessonPlanStep.numWords + 1 < this.words.length) {
        this.lessonPlanStep.numWords += 1;
      }
    }
    this.updateLessonPlanStep();
  }

  returnWords(word) {
    this.slicedWords = JSON.parse(JSON.stringify(this.slicedWords));
    if (this.lessonPlanStep.numWords === 0) {
      this.slicedWords = [];
    }
    this.slicedWords.push(word);
    this.lessonPlanStep = {...{}, ...this.lessonPlanStep};
    this.lessonPlanStep.numWords += 1;
    this.updateLessonPlanStep();
    this.makeWordTable();
    this.topTableScrollBottom();
  }

  closeMoreWords() {
    // this.moreWordsSubject.next([]);
    this.showMoreWords = false;
    // this.moreWords = [];
  }
  movedWord(event: any) {
    // @ts-ignore
    let stableDiv = this.reorderTable.nativeElement.getBoundingClientRect();
    let movingDiv = event.pointerPosition;
    let mirror = event.source.element.nativeElement.getBoundingClientRect();

    let verticalPredicate = movingDiv.y + mirror.height >= stableDiv.y
    && movingDiv.y <= stableDiv.y + stableDiv.height;

  if (verticalPredicate) {
      this.canAdd = true;

    } else {
      this.canAdd = false;

    }

  }

  sortWords() {

    if (this.wordsOrder === 'word') {
      this.moreWordsSubject.next(JSON.parse(JSON.stringify(this.responseWords)));
      if (this.courseWorkbook.workbook.filters.exactWords?.length) {
        this.wordsOrder = 'exactWordsOrder';
      } else {
        this.wordsOrder = null;
      }
    } else {
      this.wordsOrder = 'word';
    }
  }

  topTableScrollBottom() {
    setTimeout(() => {
      this.topWordsTableContainer.nativeElement.scrollTop = this.topWordsTableContainer.nativeElement.scrollHeight;
    }, 1000);
  }

}
