export const PdfSizes = {
  small: {
    value: .65,
    order: 0
  },
  small_medium:
  {
    value: .85,
    order: 1
  } ,
  medium:
  {
    value: 1,
    order: 2
  },
  medium_large:  {
    value: 1.25,
    order: 3
  } ,
  large:
  {
    value: 1.5,
    order: 4
  }
};
