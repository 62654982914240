import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Console } from "console";
import { BehaviorSubject, fromEvent, timer } from "rxjs";
import { debounceTime, delay, filter, take, tap } from "rxjs/operators";
import { getCurrentSetting, selectCurrentTheme } from "../../core/store";
import { FabricCustom } from "../fabric-custom";
import { WorkbookActivityService, fontSizes } from "./workbook-activity.service";
import FontFaceObserver from 'fontfaceobserver';
import { PdfSizes } from "../../models/pdf-sizes";

declare var fabric: any;

export type ActivityTool = "draw" | "text" | "eraser" | "select" | 'stamp' | 'pointer';

export enum FontFamilyEum {
  "ff-arial" = "Arial",
  "ff-century" = "Century Gothic",
  "ff-helvetica" = "Helvetica",
  "ff-opendyslexic" = "OpenDyslexic",
}

export enum StampSizes {
  'small' = 25,
  's/m' = 50,
  'medium' = 100,
  'm/l' = 150,
  'l' = 240
};

const originalRender = fabric.Object.prototype._render;
fabric.Object.prototype._render = function (ctx, exit: boolean) {
  originalRender.call(this, ctx);
  if (this.active) { return; }
  if (this.showTextBoxBorder) {
    const w = this.width + 16,
      h = this.height + 16,
      x = (-this.width / 2) - 8,
      y = (-this.height / 2) - 8;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + w, y);
    ctx.lineTo(x + w, y + h);
    ctx.lineTo(x, y + h);
    ctx.lineTo(x, y);
    ctx.closePath();
    const stroke = ctx.strokeStyle;
    ctx.strokeStyle = this.cTextboxBorderColor;
    ctx.stroke();
    ctx.strokeStyle = stroke;
  }
};
fabric.Object.prototype.cacheProperties =
  fabric.Object.prototype.cacheProperties.concat("active");

@Injectable({
  providedIn: "root",
})
export class ActivityCanvasService {
  pdfSizes =  Object.values(PdfSizes).map(item => item.value).sort();

  get tool(): ActivityTool {
    return this._tool;
  }

  set tool(value) {
    this._tool = value;
  }

  get lineWidth() {
    return this._lineWidth;
  }

  get isPadToggled() {
    return this._isPadToggled;
  }

  get fontSize() {
    return this._fontSize;
  }

  set fontSize(size: number) {
    this._fontSize = size;
    ActivityCanvasService.fontSize = size;
  }

  get markerColor() {
    return this._markerColor;
  }

  get canvasSize() {
    return ActivityCanvasService.canvasSize;
  }

  get activeCanvas() {
    return ActivityCanvasService.activeCanvas;
  }
  constructor(private _store: Store, private router: Router, private workbookActivityService: WorkbookActivityService) {
    this._store.select(selectCurrentTheme)
    .subscribe((theme) => {
      this.isDarkTheme = theme === 'theme-dark' ? true : false;
      this.stampCursor = `url("/assets/svgs/icons/stamp${this.isDarkTheme ? '-white' : ''}.svg") 0 32, auto`;
      this.drawCursor = `url("/assets/svgs/regular/draw-cursor${this.isDarkTheme ? '-white' : ''}.svg"), auto`;
    });

    this._store.select(getCurrentSetting)
      .pipe(filter(res => !!res), take(1))
      .subscribe(setting => {
        if (!setting) { return; }
        if (!ActivityCanvasService.stampSize) {
          ActivityCanvasService.stampSize = StampSizes[setting.stampSize] || StampSizes['s/m'];
        }
        this.setFont(setting.textfont);
        if (this.pdfSizes.includes(setting.pdfViewer.zoom)) {
          this.pdfZoomSize = setting.pdfViewer.zoom;
        } else {
          this.pdfZoomSize = 1;
        }
        this.setMarkerWidth(setting.markerWidth ?? 3);
        this.setMarkerColor(setting.markerColor ?? '#000');
        this.setStamp(setting.stamp ?? 'check');
        this.toggleFillStamp(setting.stampFilled ?? true);
        this.setStampColor(setting.stampColor ?? '#000');
        this.isShowMarkerControls = !!setting.isShowMarkerControls;
        this.isShowStampControls = !!setting.isShowStampControls;
      });

    FabricCustom.init();

    fabric.Object.prototype.controls.br.actionHandler = fabric.controlsUtils.wrapWithFireEvent('resizing', fabric.controlsUtils.wrapWithFixedAnchor(this.resize));

    fabric.Object.prototype.controls.tr.mouseUpHandler = this.deleteObject;


  }
  public static canvasSize = { width: 0, height: 0 };
  public static activeCanvas;
  public static fontSize;
  public static stampSize;


  static font;

  stampCursor = '';
  textCursor = '';
  drawCursor = '';
  pdfZoomSize: number;
  zoomSize = 1

  canvases = [];

  objectId = 0;

  disappearLinesEnabled = false;
  disappearLinesObs;
  public isDarkTheme: boolean;

  private _markerColor = "black";
  private _tool: ActivityTool = "pointer";
  private _lineWidth = 3;
  private _isPadToggled: boolean;

  private _font: string;
  private _fontSize: number;

  directory = 'assets/svgs/icons';

  stamps = {
    check: `${this.directory}/check-filled.svg`,
    circle: `${this.directory}/circle-filled.svg`,
    heart: `${this.directory}/heart-filled.svg`,
    square: `${this.directory}/square-filled.svg`,
    star: `${this.directory}/star-filled.svg`,
    triangle: `${this.directory}/triangle-filled.svg`,
    close: `${this.directory}/close-filled.svg`,
    roundedRectangle: `${this.directory}/rounded-rectangle-filled.svg`,
    diamond: `${this.directory}/diamonds-filled.svg`,
    pentagon: `${this.directory}/pentagon-filled.svg`,
    hexagon: `${this.directory}/hexagon-filled.svg`,
    octagon: `${this.directory}/octagon-filled.svg`,
    oval: `${this.directory}/ellipse-filled.svg`,
    ovalVertical: `${this.directory}/oval-filled.svg`,
    left: `${this.directory}/left-arrow-filled.svg`,
    right: `${this.directory}/right-arrow-filled.svg`,
    up: `${this.directory}/up-arrow-filled.svg`,
    down: `${this.directory}/down-arrow-filled.svg`,
    minus: `${this.directory}/minus-filled.svg`,
    plus: `${this.directory}/plus-filled.svg`,
    breve: `${this.directory}/breve-filled.svg`,
  };

  wordFrames = {
    rectangle: `${this.directory}/rectangle.svg`,
    wordFrame: `${this.directory}/word-frame.svg`,
    smallRectangle: `${this.directory}/small-rectangle.svg`,
    smallWordFrame: `${this.directory}/small-word-frame.svg`,
  }


  stamp = 'check';
  activeStamp = this.stamps[this.stamp];
  stampColor: string;
  isStampFilled = true;
  isShowStampControls = false;
  isShowMarkerControls = false;
  isActiveTextboxEditing = false;


  textCursorpositions = [
    { x: 8, y: 8 },
    { x: 12, y: 12 },
    { x: 16, y: 18 },
    { x: 20, y: 22 },
    { x: 24, y: 26 },
    { x: 28, y: 28 },
    { x: 32, y: 34 },
  ];
  static toJSON() {
    let firstDisappearCreated;
    if (!ActivityCanvasService.activeCanvas) { return; }

    if (this.activeCanvas.cHistoryIndex == null || isNaN(this.activeCanvas.cHistoryIndex)) {
      this.activeCanvas.cHistoryIndex = -1;
    }

    ActivityCanvasService.activeCanvas._objects.forEach(item => {
      if (item.cType === 'draw-disappear') {
        if (!firstDisappearCreated) {
          firstDisappearCreated = item.cCreated;
          item.cInterval = 0;
        } else {
          item.cInterval = item.cCreated - firstDisappearCreated;
        }
      }
    });

    this.activeCanvas.cHistoryIndex++;
    if (ActivityCanvasService.activeCanvas.history[this.activeCanvas.cHistoryIndex]) {
      ActivityCanvasService.activeCanvas.history = ActivityCanvasService.activeCanvas.history.slice(0, this.activeCanvas.cHistoryIndex);
    }

    ActivityCanvasService.activeCanvas.renderAll();
    const stateObject = ActivityCanvasService.activeCanvas.toJSON([
      "cType",
      "cTextboxBorderColor",
      "cTopValues",
      "cMaxFontSize",
      "cCreated",
      "cInterval",
      "cMinWidth",
      "cMaxWidth",
      "cMinHeight",
      "cMinTextLines",
      'cHistoryIndex',
      'cPath',
      'cStampPath',
      'cOriginLeft',
      'cOriginTop',
      'cOriginWidth',
      'cOriginHeight',
      'id',
      'cTrashcanLocation',
      'angle',
      'viewBoxHeight',
      'viewBoxWidth',
      'cornerSize',
      'padding',
      'hasBorders',
      'selectable',
      'angle'
    ]);
    ActivityCanvasService.activeCanvas.history.push(stateObject);
  }

  addCanvas(surface) {
    this.canvases.push(surface);
  }
  activateTool(tool: ActivityTool, isEdit?: boolean) {
    this.tool = tool;
    this.isActiveTextboxEditing = false;
    if (!ActivityCanvasService.activeCanvas) {
      return;
    }
    if (isEdit) { return; }
    ActivityCanvasService.activeCanvas.discardActiveObject();
    ActivityCanvasService.activeCanvas?.renderAll();
    if (ActivityCanvasService.activeCanvas) {
      ActivityCanvasService.activeCanvas.isDrawingMode = false;
    }

    if (tool === "draw") {
      ActivityCanvasService.activeCanvas.freeDrawingCursor = this.drawCursor;
      ActivityCanvasService.activeCanvas.freeDrawingBrush = new fabric.PencilBrush(
        ActivityCanvasService.activeCanvas
      );
      ActivityCanvasService.activeCanvas.freeDrawingBrush.width = this._lineWidth;
      ActivityCanvasService.activeCanvas.freeDrawingBrush.color = this._markerColor;
      ActivityCanvasService.activeCanvas.isDrawingMode = true;
    } else if (tool === "eraser") {
      ActivityCanvasService.activeCanvas.freeDrawingBrush = new fabric.EraserBrush(
        ActivityCanvasService.activeCanvas
      );
      ActivityCanvasService.activeCanvas.freeDrawingBrush.width = 36;
      ActivityCanvasService.activeCanvas.isDrawingMode = true;
      ActivityCanvasService.activeCanvas.freeDrawingCursor =
        `url("/assets/svgs/regular/eraser-cursor${this.isDarkTheme ? '-white' : ''}.svg") 10 10, auto`;
    } else if (tool === "text") {
      this.setDefaultCursor("text");
      ActivityCanvasService.activeCanvas._objects.forEach((obj) => {
        if ("text" in obj) {
          obj.bringToFront();
        }
      });
    } else if (tool === 'stamp') {
      ActivityCanvasService.activeCanvas._objects.forEach((obj) => {
        if (obj && obj.cType === 'stamp') {
          obj.bringToFront();
        }
      });

      this.setDefaultCursor('stamp');
      ActivityCanvasService.activeCanvas?.renderAll();
    } else if (tool === 'pointer') {
      this.setDefaultCursor('pointer');
    }
    const objects = this.activeCanvas.getObjects();
    objects?.forEach(obj => {
      obj.selectable = tool !== 'pointer';

      if (tool === 'pointer') {
        obj.selectable = false;
        obj.hoverCursor = 'default';
      } else {
        if (tool === 'draw' || tool === 'stamp') {
          obj.hoverCursor = 'move';
        }
      }
    });
  }

  addText(x, y, activityName) {
    const zoomSize = activityName === 'pdf-viewer' ? this.pdfZoomSize : this.zoomSize;
    const topOffset = {
      0.65: 12,
      0.85: 6,
      1.25: -6,
      1.5: -12
    };
    if (x < 10) { x = 10 + 4; }
    let top = y - ActivityCanvasService.fontSize / 2;

    const activeTextbox = new fabric.Textbox("", {
      showTextBoxBorder: true,
      width: 20,
      top: (top / zoomSize) + (topOffset[zoomSize] || 0),
      left: x / zoomSize,
      fontSize: ActivityCanvasService.fontSize,
      maxFontSize: 64,
      stroke: this.isDarkTheme && !this.router.url.includes('pdf-viewer') ? '#f1eeff' : 'black',
      fill: this.isDarkTheme && !this.router.url.includes('pdf-viewer') ? '#f1eeff' : 'black' ,
      erasable: false,
      editingBorderColor: "rgba(134,109,255)",
      padding: 10,

      cornerSize: 32,
      lineHeight: 1,
      hoverCursor: 'text',
      moveCursor: 'text',
      perPixelTargetFind: false,
      // CUSTOM PROPERTIES
      cType: 'text',
      cTextboxBorderColor: "rgba(134,109,255,0.25)",
      cTopValues: Array(5).fill(0),
      cMaxFontSize: 64,
    });

    if (activeTextbox.left < 10) { activeTextbox.left = 10 + 4; }
    if (activeTextbox.top < 15) { activeTextbox.top = 15 + 8; }
    if ((activeTextbox.left + (ActivityCanvasService.fontSize + 8)) > this.canvasSize.width) {
      activeTextbox.left = this.canvasSize.width - (ActivityCanvasService.fontSize + 8);
    }
    activeTextbox.controls.tr.x = 0.5;
    activeTextbox.controls.tr.y = -0.5;
    activeTextbox.cTrashcanLocation = {
      x: activeTextbox.controls.tr.x,
      y: activeTextbox.controls.tr.y
    };
    activeTextbox.objectId = this.objectId++,

    ActivityCanvasService.activeCanvas.add(activeTextbox);
    ActivityCanvasService.activeCanvas.setActiveObject(activeTextbox);
    this.textboxEvents(activeTextbox, x, y);
    activeTextbox.enterEditing();

    let fontFamily = FontFamilyEum[this._font];
    if (fontFamily === 'Arial' || fontFamily === 'Helvetica') {
      this.activeCanvas.getActiveObject().set("fontFamily", fontFamily);
      this.activeCanvas.requestRenderAll();
    } else {
      const font = new FontFaceObserver(fontFamily);
      font.load()
        .then(res => {
          this.activeCanvas.getActiveObject().set("fontFamily", res.family);
          this.activeCanvas.requestRenderAll();
        })
        .catch(err => {
          console.error('loading font error', err);
        });
    }
  }

  textboxEvents(activeTextbox, x, y) {
    let minWWidth = activeTextbox.width;
    const leftRightPadding = 20;
    const controlIconWidth = 24;
    let extraSpace = 0;

    if (this.pdfZoomSize === 0.65) {
      extraSpace = 34;
    } else if (this.pdfZoomSize === 0.85) {
      extraSpace = 27;
    } else if (this.pdfZoomSize === 1) {
      extraSpace = 24;
    } else if (this.pdfZoomSize === 1.25) {
      extraSpace = 20;
    } else if (this.pdfZoomSize === 1.5) {
      extraSpace = 16;
    }

    activeTextbox.setControlsVisibility({
      mtr: false,
      mb: false,
      ml: false,
      mr: false,
    });

    activeTextbox.on("editing:entered", (event) => {
      this.tool = 'text';
      activeTextbox.cTopValues = Array(5).fill(0);
      ActivityCanvasService.fontSize = activeTextbox.fontSize;
      activeTextbox.hasControls = true;

      this.isActiveTextboxEditing = true;
      // if (this.router.url.includes('pdf-viewer') && this.activeCanvas.id === 'main-canvas') {
      //   const pdf = this.activeCanvas.getObjects().find(obj => obj.cType === 'pdf');
      //   activeTextbox.cMaxWidth = ((pdf.width * pdf.scaleX) - ((x) / this.pdfZoomSize) - extraSpace);
      // } else {
        activeTextbox.cMaxWidth = ActivityCanvasService.canvasSize.width - x - 20;
      // }
    });


    fromEvent(activeTextbox, 'editing:exited')
      .subscribe(event => {
        if (!activeTextbox?.text) {
          ActivityCanvasService.activeCanvas.remove(activeTextbox);
        }
        ActivityCanvasService.toJSON();
      });

    fromEvent(activeTextbox, 'changed')
      .pipe(tap(() => {
        if (activeTextbox.cursorOffsetCache.top > 0) { return; }
        if (
          activeTextbox.cursorOffsetCache.left > (activeTextbox.width - (activeTextbox.fontSize))) {
            if (activeTextbox.cursorOffsetCache.left < (activeTextbox.cMaxWidth)) {
              activeTextbox.width = activeTextbox.cursorOffsetCache.left + (activeTextbox.fontSize);
              activeTextbox.cMinWidth = activeTextbox.width;
            } else {
              activeTextbox.width = activeTextbox.cMaxWidth;
            }
        }
      }), debounceTime(100))
      .subscribe(event => {
        let textLines = [...activeTextbox.textLines];
        const emptyStartIndex = textLines.reverse().findIndex(item => {
          return !!item.trim();
        });
        textLines.reverse();
        if (emptyStartIndex) {
          textLines = textLines.slice(0, emptyStartIndex * -1);
        }
        activeTextbox.cMinHeight = (activeTextbox.__lineHeights[0]) * textLines.length;
        activeTextbox.cMinTextLines = textLines;
    });

    activeTextbox.on('resizing', event => {
      const target = event.transform.target;
      if (!target) { return; }
      if (target.height < target.cMinHeight) {
        target.height = target.cMinHeight;
      }
      if ((target.left + target.width + 16) > this.canvasSize.width) {
        target.width = this.canvasSize.width - target.left - 16;
      }

      if (target.width < (target.cMinWidth + ActivityCanvasService.fontSize / 3)) {
        target.width = activeTextbox.cMinWidth + ActivityCanvasService.fontSize / 3;
      }

      const rowsLength = Math.floor((target.height) / target.__lineHeights[0]);
      if (rowsLength !== target.textLines?.length) {
        if (rowsLength > target.textLines?.length) {
          target.text += '\n';
        } else {
          target.text = target.text.substring(0, target.text.length - 1);
        }
      }

    });
  }

  public setMarkerColor(color) {
    this._markerColor = color;
    if (this.tool === "draw" || this.tool === "text") {
      this.activateTool(this.tool);
    }
  }

  public setStampColor(color) {
    this.stampColor = color;
  }

  public setMarkerWidth(width) {
    this._lineWidth = width;
    if (this.tool === "draw") {
      this.activateTool(this.tool);
    }
  }

  public togglePad(state?: boolean) {
    this._isPadToggled = state ?? !this._isPadToggled;
    if (this._isPadToggled) {
      this.selectCanvas("pad-canvas");
    } else {
      this.selectCanvas("main-canvas");
    }
  }

  public clearCanvas() {
    this.activeCanvas?.discardActiveObject();
    this.canvases.forEach((canvas) => {
      canvas._objects = [];
      canvas.cHistoryIndex = -1;
      canvas.history = [];

      canvas?.renderAll();
    });
  }
  setFont(font: string) {
    this._font = font;
    ActivityCanvasService.font = font;
  }

  getCanvas(canvasId: string) {
    return this.canvases.find(
      (canvas) => canvas.id === canvasId
    );
  }

  selectCanvas(canvasId: string) {
    ActivityCanvasService.activeCanvas?.discardActiveObject();
    ActivityCanvasService.activeCanvas?.renderAll();
    ActivityCanvasService.activeCanvas = this.canvases.find(
      (canvas) => canvas.id === canvasId
    );
    this.activateTool(this.tool);
  }

  removeCanvas(canvasId: string) {
    this.canvases = this.canvases.filter(
      (canvas) => canvas.id !== canvasId
    );
  }

  setDefaultCursor(type: ActivityTool) {
    if (!ActivityCanvasService.activeCanvas) { return; }
    if (type === "text") {
      const textCursorPosition = this.textCursorpositions[fontSizes.indexOf(ActivityCanvasService.fontSize)];
      const cursor = `url("/assets/svgs/regular/text-cursor-${ActivityCanvasService.fontSize}px${this.isDarkTheme && !this.router.url.includes('pdf-viewer') ? '-white' : ''}.svg") ${textCursorPosition.x} ${textCursorPosition.y}, auto`;
      this.textCursor = cursor;
      ActivityCanvasService.activeCanvas.defaultCursor = cursor;
      ActivityCanvasService.activeCanvas._objects.forEach(obj => {
        if (obj.cType === 'draw') {
          obj.hoverCursor = this.textCursor;
        }
      });
      ActivityCanvasService.activeCanvas?.renderAll();
    } else if (type === 'stamp') {
      ActivityCanvasService.activeCanvas.defaultCursor = this.stampCursor;
      ActivityCanvasService.activeCanvas._objects.forEach(obj => obj.hoverCursor = obj.cType === 'draw' ? this.stampCursor : 'move');
      ActivityCanvasService.activeCanvas?.renderAll();
    } else if (type === 'draw') {
      ActivityCanvasService.activeCanvas.isDrawingMode = true;
    } else if (type === 'pointer') {
      ActivityCanvasService.activeCanvas.defaultCursor = 'default';
    }
  }

  resize(eventData, transform, x, y) {
    if ((x - transform.target.left) < transform.target.cMinWidth) {
      x = transform.target.left + transform.target.cMinWidth;
    }
    if (transform?.target.cType === 'text' && !transform?.target?.text) { return; }

    if (transform.target.cType === 'text') {
      let target = transform.target;
      let localPoint = fabric.controlsUtils.getLocalPoint(
        transform,
        transform.originX,
        transform.originY,
        x,
        y
      );

      let oldHeight = target.height;
      let oldWidth = target.width;
      let newWidth =
        Math.abs(localPoint.x);
      let newHeight =
        Math.abs(localPoint.y);
      if (!transform?.target) { return; }
        target.set("width", Math.max(newWidth, 0));
        target.set("height", Math.max(newHeight, 0));
        return !(oldWidth === newWidth && oldHeight === newHeight);
    }

    if (transform.target.cType === 'stamp') {
      const cWidth = x - transform.target.left;
      const cHeight = y - transform.target.top;
      if (y > ActivityCanvasService.canvasSize.height - 16) { return; }
      fabric.controlsUtils.scalingEqually(eventData, transform, x, y);
      ActivityCanvasService.stampSize = transform.target.width * transform.target.scaleX;
      return true;
    } else if (transform.target.cType === 'draw') {
      fabric.controlsUtils.scalingEqually(eventData, transform, x, y);
      ActivityCanvasService.stampSize = transform.target.width * transform.target.scaleX;
      return true;
    }
  }

  deleteObject(eventData, transform) {
    const target = transform?.target;
    if (!target || transform.action !== 'remove') { return; }
    ActivityCanvasService.toJSON();
    ActivityCanvasService.activeCanvas.remove(target);
    ActivityCanvasService.activeCanvas.requestRenderAll();
  }

  undo() {
    let json;
    if (ActivityCanvasService?.activeCanvas) {
      if (this.activeCanvas.cHistoryIndex === -1) { return; }
      this.activeCanvas.cHistoryIndex--;
      if (this.activeCanvas.cHistoryIndex >= 0) {
        json =
          ActivityCanvasService.activeCanvas.history[this.activeCanvas.cHistoryIndex];
      } else {
        json = { objects: [] };
      }
      this.loadJSON(json);
    }
  }

  redo() {
    if (ActivityCanvasService?.activeCanvas) {
      if (this.activeCanvas.cHistoryIndex === (ActivityCanvasService.activeCanvas.history.length - 1)) { return; }
      this.activeCanvas.cHistoryIndex++;
      let json = ActivityCanvasService.activeCanvas.history[this.activeCanvas.cHistoryIndex];
      this.loadJSON(json);
    }
  }

  toggleDisappearLines(isEnabled: boolean) {
    this.disappearLinesEnabled = isEnabled;
  }

  startDisappear() {
    this.disappearLinesObs = timer(1000, 1000).subscribe(res => {
      const objs = ActivityCanvasService.activeCanvas?._objects;
      if (!objs) {
        this.disappearLinesObs.unsubscribe();
        return;
      }
      const disappearingObjects = objs.filter(item => item.cType === 'draw-disappear');
      if (disappearingObjects?.length) {
        disappearingObjects.forEach((obj) => {
          if (obj.cType === 'draw-disappear' && obj.cCreated < (Date.now() - 5000) && !obj.deleting) {
            obj.deleting = true;
            obj.animate('opacity', 0, {
              duration: 2000,
              onChange: ActivityCanvasService.activeCanvas?.renderAll.bind(ActivityCanvasService.activeCanvas),
              onComplete: () => {
                ActivityCanvasService.activeCanvas.remove(obj);
                ActivityCanvasService.toJSON();
              },
              easing: fabric.util.ease['easeInQuad']
            });
          }
        });
      } else {
        this.disappearLinesObs.unsubscribe();
      }
    });
  }

  toggleFillStamp(isFilled: boolean) {
    this.isStampFilled = isFilled;
  }

  toggleStampControls(value: boolean) {
    this.isShowStampControls = value;
    this.canvases.forEach(canvas => {
      canvas._objects.forEach(obj => {
        if (obj.cType === 'stamp') {
          obj.selectable = value;
          obj.hasControls = value;
          obj.hoverCursor = value ? 'move' : 'default';
        }
      });
      canvas.renderAll();
    });
  }

  toggleMarkerControls(value) {
    this.isShowMarkerControls = value;
    this.canvases.forEach(canvas => {
      canvas._objects.forEach(obj => {
        if (obj.cType === 'draw') {
          obj.selectable = value;
          obj.hasControls = value;
          obj.hoverCursor = value ? 'move' : 'default';
        }
      });
      canvas.renderAll();
    });
  }

  setStamp(stamp) {
    this.stamp = stamp;
    this.activeStamp = this.stamps[stamp] || this.wordFrames[stamp];
  }

  setStampSize(size: 'small' | 's/m' | 'medium' | 'm/l' | 'large') {
    ActivityCanvasService.stampSize = StampSizes[size];
  }

  addStamp(x, y, activityName?) {
    const zoomSize = activityName === 'pdf-viewer' ? this.pdfZoomSize : this.zoomSize;
    let top = (y + 1) / zoomSize;
    let left = (x - 1) / zoomSize;
    if (!ActivityCanvasService?.activeCanvas) { return; }
    fabric.loadSVGFromURL(this.activeStamp, (objects, options) => {
        const loadedObject = fabric.util.groupSVGElements(objects, options);
        setTimeout(() => {
          const icon: any = {
            left,
            top,
            fill: this.isStampFilled ? this.stampColor : 'transparent',
            stroke: this.stampColor,
            strokeWidth: 16,
            cType: 'stamp',
            showTextBoxBorder: true,
            cTextboxBorderColor: "rgba(134,109,255,0.25)",
            perPixelTargetFind: false,
            cornerSize: 32,
            erasable: false,
            padding: 8,
            selectable: this.isShowStampControls,
            hasControls: this.isShowStampControls,
            hoverCursor: this.isShowStampControls ? 'move' : 'default',
            cStampPath: this.activeStamp
          };
          if (this.activeStamp.includes('word-frame') || this.activeStamp.includes('small-rectangle')) {
            icon.fill = this.stampColor;
            icon.stroke = this.stampColor;
            icon.strokeWidth = 0;
          }
          else if (this.activeStamp.includes('icons/rectangle.svg')) {
            icon.fill = 'transparent';
            icon.stroke = this.stampColor;
          }
          loadedObject.set(icon);

          loadedObject.scaleToWidth(ActivityCanvasService.stampSize);

          const stampWidth = (loadedObject.width * loadedObject.scaleX);
          const stampHeight = loadedObject.height * loadedObject.scaleY;

          loadedObject.top -= stampHeight;
          if ((loadedObject.left + stampWidth) > (ActivityCanvasService.canvasSize.width)) {
            loadedObject.left = (ActivityCanvasService.canvasSize.width) - stampWidth - 16;
          }

          if (loadedObject.top < 15) {
            loadedObject.top = 15 + 8;
          }
          this.setStampEvents(loadedObject, loadedObject.top);

          this.setDefaultCursor(null);
          loadedObject.controls.tr.x = 0.5;
          loadedObject.controls.tr.y = -0.5;
          loadedObject.cTrashcanLocation = {
            x: loadedObject.controls.tr.x,
            y: loadedObject.controls.tr.y
          };
          loadedObject.objectId = this.objectId++,

          ActivityCanvasService.activeCanvas.add(loadedObject);
          if (this.isShowStampControls) {
            ActivityCanvasService.activeCanvas.setActiveObject(loadedObject);
          }
          ActivityCanvasService.activeCanvas?.renderAll();
          ActivityCanvasService.toJSON();
        }, 100);
      });
  }

  setStampEvents(obj, top) {
    obj.setControlsVisibility({
      mtr: false,
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      tl: false,
      bl: false,
    });

    obj.on('scaling', (event) => {
      const {left, scaleX, height, scaleY, width, viewBoxHeight, viewBoxWidth, cStampPath} = event.transform.target;
      let maxWidth = StampSizes.l;
      if ((left + maxWidth + 16) > ActivityCanvasService.canvasSize.width) {
        maxWidth = ActivityCanvasService.canvasSize.width - left - 16;
      }
      const maxScaleX = maxWidth / width;
      const minScaleX = StampSizes.small / width;

      if (scaleX > maxScaleX) {
        event.transform.target.scaleX = maxScaleX;
        let heightRatio = 1;
        if (cStampPath.includes('icons/rectangle.svg')) {
          heightRatio = 0.34;
        } else if (cStampPath.includes('icons/small-rectangle.svg')) {
          heightRatio = 0.34
        }
        const scaleY = viewBoxHeight / (viewBoxWidth / event.transform.target.scaleX * heightRatio);
        event.transform.target.scaleY = scaleY;
      } else if (scaleX < minScaleX) {
        event.transform.target.scaleX = minScaleX;
        const scaleY = viewBoxHeight / (viewBoxWidth / event.transform.target.scaleX);
        event.transform.target.scaleY = scaleY;
      }
    });
  }

  loadJSON(json) {
    const jsonData = { ...json };
    ActivityCanvasService.activeCanvas.loadFromJSON(
      jsonData,
      ActivityCanvasService.activeCanvas?.renderAll.bind(ActivityCanvasService.activeCanvas),
      (o, object) => {
        if (object.cType === 'stamp') {
          this.setStampEvents(object, object.top);
        } else if (object.cType === 'draw') {
          object.setControlsVisibility({
            bl: false,
            br: false,
            tl: false,
            mb: false,
            ml: false,
            mr: false,
            mt: false,
            mtr: false
          });
        } else if (object.cType === 'text') {
          this.textboxEvents(object, object.left, object.top);
        } else {
          if (object.cType === 'draw-disappear') {
            object.cCreated = Date.now() + object.cInterval;
            if (!this.disappearLinesObs || this.disappearLinesObs?.closed) {
              this.startDisappear();
            }
          }
        }
      }
    );
    ActivityCanvasService.activeCanvas.discardActiveObject();
    ActivityCanvasService.activeCanvas?.renderAll();
  }

  deleteAllCanvases() {
    ActivityCanvasService.activeCanvas = null;
    this.canvases = [];
  }

  setZoom(size) {
    if (!this.activeCanvas) { return; }
    this.activeCanvas.setZoom(size);
    this.activeCanvas.setDimensions({
      width: ActivityCanvasService.canvasSize.width * size,
      height: ActivityCanvasService.canvasSize.height * size,
    })
  }
}
